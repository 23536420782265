<template>
  <div style="background: white">
    <!-- ローディング画面 -->
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_locationBarcode" />
      <v-form ref="form" lazy-validation>
        <div id="app">
          <!-- CSVファイル選択です。 -->
          <div class="file_input">
            <v-file-input label="CSVファイルを選択" @change="getFileContent" />
          </div>

          <!-- バーコード表示領域です。 -->
          <v-container>
            <!-- 各行です。 -->
            <v-row>
              <!-- 各列です。 -->
              <v-col class="column" v-for="(obj, index) in content" :key="index">
                <!-- バーコード画像です。 -->
                <div class="barcodeImg">
                  <img :src="obj.URL" />
                </div>

                <!-- ロケーションSIDです。 -->
                <div class="location_sid">{{ obj.location_sid }}</div>

                <!-- ロケーションNoです。 -->
                <div class="location_no">{{ obj.location_no }}</div>

                <!-- 偶数番目の要素の場合、改行します。 -->
                <div v-if="index / 2 == 0"><br /></div>
              </v-col>

              <!-- 奇数番目の要素の場合、空の列を追加します。 -->
              <v-col v-if="content.length / 2 != 0"></v-col>
            </v-row>
          </v-container>
        </div>
      </v-form>

      <!-- ナビゲーションドロワー -->
      <!-- 開閉状態バインディング(openMenu) -->
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <!-- サイドメニュー -->
        <SideMenu></SideMenu>
      </v-navigation-drawer>
    </v-sheet>
  </div>
</template>

<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { barcodeUtil } from "../../assets/scripts/js/BarcodeUtil";
import NavBar from "../../components/NavBar.vue"; // ナビゲーションバー
import Loading from "@/components/loading"; // ローディング画面
import SideMenu from "@/components/Menu"; // サイドメニュー

export default {
  components: {
    Loading,
    NavBar,
    SideMenu,
  },
  props: {},

  // データプロパティです。
  data: () => ({
    name: appConfig.MENU_ID.P_MNG_CREATE_LOCATION_BARCODE,
    // メニュー
    openMenu: null,
    // ローディング画面表示フラグ
    loadingCounter: 0,

    // CSVファイルから読み込んだ内容です。
    content: [{}],
  }),

  // メソッドです。
  methods: {
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    // ファイル変更時イベントです。
    async getFileContent(file) {
      try {
        // ファイル読み込み処理を実行します。
        const content = await this.readFileAsync(file);

        // 読み込んだ内容を行ごとに分割します。
        let fileResult = content.split("\r\n");

        // 先頭行をヘッダーとして格納します。
        let header = fileResult[0].split(",");

        // 先頭行を削除します。
        fileResult.shift();

        // 二行目以降から情報を取得します。
        let items = fileResult.map((item) => {
          // カンマ区切りで行データを取得します。
          let datas = item.split(",");

          // 空のマップを生成します。
          let result = {};

          // 各データに対してループします。
          for (const index in datas) {
            // ヘッダーからキーを取得します。
            let key = header[index];

            // マップにデータを登録します。
            result[key] = datas[index];
          }

          // ロケーションSIDをフォーマットします。
          result["location_sid"] = "*" + this.barcode(result["location_sid"]) + "*";

          // バーコード画像のURLを生成します。
          result["URL"] = barcodeUtil.getImage(result["location_sid"]);

          // マップを返します。
          return result;
        });

        // 読み込んだ内容をデータプロパティに格納します。
        this.content = items;

        // 例外処理です。
      } catch (e) {
        // ログに表示します。
        console.log(e);
      }
    },

    // ファイル読み込み処理です。
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        // ファイルリーダーを生成します。
        const reader = new FileReader();

        // 読み込み時イベントを設定します。
        reader.onload = () => {
          // 読み込んだ内容を返します。
          resolve(reader.result);
        };

        // エラー時の挙動を設定します。
        reader.onerror = reject;

        // ファイルをテキストとして読み込みます。
        reader.readAsText(file);
      });
    },

    // 入力値をフォーマットします。
    barcode(value) {
      // 全角文字を半角文字に変換します。
      value = this.fullWidth2HalfWidth(value);

      // 半角英数記号以外の文字をブランクに変換します。
      value = value.replace(/[^0-9a-zA-Z\-.$/+% ]+/gi, "");

      // 小文字を大文字に変換します。
      value = value.toUpperCase();

      // 値を返します。
      return value;
    },

    // 全角文字を半角文字に変換します。
    fullWidth2HalfWidth(str) {
      return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });
    },
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.file_input {
  /* 最大幅指定です。 */
  max-width: 30%;
}

.column {
  /* コンテンツの幅に合わせます。 */
  width: fit-content;
}

.barcodeImg {
  /* 中央寄せ指定です。 */
  margin: 0 auto;
  /* コンテンツの幅に合わせます。 */
  width: fit-content;
}

.location_sid {
  /* 中央寄せ指定です。 */
  margin: 0 auto;
  /* コンテンツの幅に合わせます。 */
  width: fit-content;
}

.location_no {
  /* 中央寄せ指定です。 */
  margin: 0 auto;
  /* フォントサイズ指定です。 */
  font-size: 500%;
  /* コンテンツの幅に合わせます。 */
  width: fit-content;
}

#app {
  /* マージン指定です。 */
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
