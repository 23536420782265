<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_traceSearch" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row" :style="searchStyle">
            <!-- 指示選択-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="deploySelected"
                :items="deployList"
                :label="$t('label.lbl_deploy_direction')"
                :rules="[rules.deployDirectionRequired]"
                class="txt-single"
                persistent-hint
                pa-3
                @change="deploySelectedCheck()"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer"></span>

            <!-- 伝票No -->
            <div class="textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="slipNo"
                maxlength="50"
                :label="$t('label.lbl_billingSlipNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 実績日(From) -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="fromDate"
                class="txt-single date-style"
                :label="$t('label.lbl_achieveDate') + '（From）'"
                @change="changeFromDate"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[rules.yyyymmdd, rules.inputFromTimeCheck(toDate, fromDate)]"
              ></v-text-field>
            </div>
            <!-- fromカレンダー -->
            <div class="calender-btn-area">
              <v-menu
                v-model="menuFromDate"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="fromDateCal" @input="menuFromDate = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="preDateOfFromDate"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDateOfFromDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダーの間 -->
            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <span class="item-spacer"></span>

            <!-- 実績日(To) -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="toDate"
                class="txt-single date-style"
                :label="$t('label.lbl_achieveDate') + '（To）'"
                @change="changeToDate"
                :rules="[rules.yyyymmdd, rules.inputToTimeCheck(toDate, fromDate)]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <!-- toカレンダー -->
            <div class="calender-btn-area">
              <v-menu
                v-model="menuToDate"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="toDateCal" @input="menuToDate = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="preDateOfToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDateOfToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row :style="searchStyle">
            <!-- メ取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_makerSupplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- メ品番/品名 -->
            <div class="serch-textbox-useritemCd">
              <v-autocomplete
                dense
                v-model="itemSelected"
                :items="itemList"
                maxlength="100"
                :hint="setItemHint()"
                @change="changeProductNm"
                :search-input.sync="itemSearch"
                :label="$t('label.lbl_makerProductCdName')"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- ロット -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="lotNo"
                maxlength="20"
                :label="$t('label.lbl_lot')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- S/N -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="serial"
                maxlength="32"
                :label="$t('label.lbl_serial')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 期限日 -->
            <div class="serch-textbox-dueDate">
              <v-text-field
                outlined
                dense
                v-model="dueDate"
                maxlength="8"
                :label="$t('label.lbl_dueDate')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 付属キー１ -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="lotSubkey1"
                maxlength="20"
                :label="$t('label.lbl_lotSubkey1')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 付属キー２ -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="lotSubkey2"
                maxlength="20"
                :label="$t('label.lbl_lotSubkey2')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
          </v-row>
          <v-row :style="searchStyle">
            <!-- ユ取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="userSuppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_userSupplier')"
                :hint="setUserSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                maxlength="100"
                pa-3
                :disabled="userDisabledFlg"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- ユ品番/品名 -->
            <div class="serch-textbox-useritemCd">
              <v-autocomplete
                dense
                v-model="userItemSelected"
                :items="userItemList"
                maxlength="100"
                :hint="setUserItemHint()"
                @change="changeUserProductNm"
                :search-input.sync="userItemSearch"
                :label="$t('label.lbl_userProductCdName')"
                persistent-hint
                :disabled="userDisabledFlg"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- ロット -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="userLotNo"
                maxlength="20"
                :label="$t('label.lbl_lot')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="userDisabledFlg"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- S/N -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="userSerial"
                maxlength="80"
                :label="$t('label.lbl_serial')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="userDisabledFlg"
              />
            </div>
          </v-row>
          <v-row :style="searchStyle">
            <!-- ロケ -->
            <div class="search-autocomplete first-search-item">
              <v-text-field
                outlined
                dense
                v-model="locationSelected"
                maxlength="80"
                :label="$t('label.lbl_location')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 担当者 -->
            <div class="serch-textbox-useritemCd">
              <v-autocomplete
                dense
                v-model="managerSelected"
                :items="managerList"
                :label="$t('label.lbl_popDestinationNm')"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 場所（入荷元/出荷先/搬入先） -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="place"
                maxlength="100"
                :label="placeName"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 管理No. -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="managementNo"
                maxlength="50"
                :label="$t('label.lbl_managementNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 管理No. -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="managementNoOrg"
                maxlength="50"
                :label="$t('label.lbl_managementNo') + '_org'"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn class="get-btn" @click="btnSearch('get-search')">
                  {{ $t("btn.btn_search") }}</v-btn
                >
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
                <!--隠すボタン-->
                <v-btn class="get-btn" :style="searchStyle" @click="btnDisiplay()">隠す</v-btn>
                <!-- 再表示ボタン -->
                <v-btn class="get-btn" :style="reStyle" @click="btnReDisiplay()">再表示</v-btn>
              </div>
              <div class="btn-search-area" style="float: right">
                <!--展開ボタン-->
                <v-btn
                  color="primary"
                  id="btn-search"
                  class="post-btn"
                  @click="openTraceDeploy()"
                  :disabled="disableBtn"
                >
                  {{ $t("btn.btn_deployment") }}</v-btn
                >
                <!--CSV出力ボタン-->
                <v-btn color="primary" id="btn-search" class="post-btn" @click="traceSearchCsv()">
                  {{ $t("btn.btn_outputcsv") }}</v-btn
                >
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>
              <!-- 検索時間表示部分 -->
              <div class="text-label" style="float: left">
                <span v-if="inputList.length != 0" class="nowTime">
                  （{{ today }} {{ nowTime }}時点）
                </span>
              </div>
              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_10_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_10_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_10_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          class="tableData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          :options.sync="sortOptions"
          height="100%"
        >
          <!-- ヘッダーロット -->
          <template v-slot:[`header.lotNo`]="{ header }">
            <span>
              <div>{{ header.text }}</div>
              <span>
                {{
                  $t("label.lbl_dueDate") +
                  "／" +
                  $t("label.lbl_lotSubkey1") +
                  "／" +
                  $t("label.lbl_lotSubkey2")
                }}
              </span>
            </span>
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              style="transform: scale(2)"
            />
          </template>
          <!-- 日付 -->
          <template v-slot:[`item.date`]="{ item }">{{ item.date }}</template>
          <!-- 品番-->
          <template v-slot:[`item.productCode`]="{ item }">
            <v-row>
              <v-col style="padding: 0 12px">
                <div v-if="item.productCodeName">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="text-overflow"
                        style="max-width: 480px; text-align: left !important"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.productCode }}<br />
                        {{ item.productCodeName }}
                      </div>
                    </template>
                    <div>{{ item.productCode }}<br />{{ item.productCodeName }}</div>
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="text-overflow"
                        style="max-width: 480px; text-align: left !important"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.productCode }}
                      </div>
                    </template>
                    <div>{{ item.productCode }}</div>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </template>
          <!-- 付帯品番 -->
          <template v-slot:[`item.accessoryProduct`]="{ item }">
            <v-row>
              <v-col v-if="item.accessoryProduct" style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="max-width: 380px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.accessoryProduct }}
                    </div>
                  </template>
                  <div>{{ item.accessoryProduct }}</div>
                </v-tooltip>
              </v-col>
              <v-col v-else>
                <div>{{ item.accessoryProduct }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- ロット-->
          <template v-slot:[`item.lotNo`]="{ item }">
            <v-row style="text-align: left !important">
              <v-col
                v-if="item.lotNo.length > 15 || item.dueDate.length > 15"
                style="padding: 0 12px"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="width: 380px" v-bind="attrs" v-on="on">
                      {{ item.lotNo }}<br />
                      {{ item.dueDate }}
                    </div>
                  </template>
                  <div>{{ item.lotNo }}<br />{{ item.dueDate }}</div>
                </v-tooltip>
              </v-col>
              <v-col v-else style="padding: 0 12px">
                {{ item.lotNo }}<br />
                {{ item.dueDate }}
              </v-col>
            </v-row>
          </template>
          <!-- 数量-->
          <template v-slot:[`item.quantity`]="{ item }">
            <div style="text-align: right !important">{{ item.quantity }}</div>
          </template>
          <!-- 入荷元/出荷先 -->
          <template v-slot:[`item.recieveShipTo`]="{ item }">
            <v-row>
              <v-col style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="max-width: 300px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.recieveShipTo }}
                    </div>
                    <div
                      class="text-overflow"
                      style="max-width: 300px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.deliveryName }}
                    </div>
                  </template>
                  <div>{{ item.recieveShipTo }}</div>
                  <div>{{ item.deliveryName }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-dialog v-model="alertDialog" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ checkMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="alertCancel"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { getParameterProcurement } from "../../assets/scripts/js/GetParameterProcurement";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
export default {
  name: appConfig.SCREEN_ID.P_MNG_010,
  components: {
    Loading,
    ConfirmDialog,
    SimpleDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // 指示選択
    deploySelected: "",
    deployList: [],
    // メーカー取引先プルダウン
    supplierList: [],
    // ユーザー取引先プルダウン
    userSupplierList: [],
    //チェック上限値
    checLlimitList: [],
    // メーカー取引先
    suppliersSelected: "",
    // ユーザー取引先
    userSuppliersSelected: "",
    // 伝票No
    slipNo: "",
    // ボタン活性化
    disableBtn: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // メーカー品番
    itemSelected: "",
    itemList: [],
    // ロット(メーカー)
    lotNo: "",
    // S/N(メーカー)
    serial: "",
    //期限日(メーカー)
    dueDate: "",
    //付属キー１(メーカー)
    lotSubkey1: "",
    //付属キー２(メーカー)
    lotSubkey2: "",
    // ユーザー品番
    userItemSelected: "",
    userItemList: [],
    // ロット(ユーザー)
    userLotNo: "",
    // S/N(ユーザー)
    userSerial: "",
    // 入荷元/出荷先/搬入先
    arrivalSelected: "",
    // ロケーション
    locationSelected: "",
    // 担当者
    managerSelected: "",
    managerList: [],
    // 場所（入荷元/出荷先/搬入先）
    place: "",
    placeName: "",
    //管理No.
    managementNo: "",
    //管理No.（元）
    managementNoOrg: "",
    u_10: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // 初期データ
    defaultData: [],
    userDisabledFlg: true,
    // 検索時点の日時
    year: "",
    today: "",
    nowTime: "",
    search: "",
    //style
    searchStyle: "",
    reStyle: "display: none;",
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 0,
    totalCount: 0,
    itemsPerPage: 500,
    itemsPerPageBtn: "500",
    // 警告ダイアログ
    alertDialog: false,
    checkMessage: "",
    // メニュー
    openMenu: null,
    checkedList: [],

    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: true,
    },
    // ソート
    sortItem: "",
    sortOptions: {},
    alertMessage: "",
    // ヘッダ
    headerItems: [
      // チェックボックス
      {
        value: "check",
        width: "3%",
        align: "center",
        sortable: false,
      },
      // 日付
      {
        text: i18n.tc("label.lbl_date"),
        value: "date",
        width: "7%",
        align: "center",
        sortable: true,
      },
      // 品番
      {
        text:
          i18n.tc("label.lbl_makerProductCd") +
          "/" +
          i18n.tc("label.lbl_userProductCd") +
          "/" +
          i18n.tc("label.lbl_productCodeName"),
        value: "productCode",
        width: "20%",
        align: "left",
        sortable: true,
      },
      // 付帯品番
      {
        text: i18n.tc("label.lbl_accessoryProduct"),
        value: "accessoryProduct",
        width: "15%",
        align: "left",
        sortable: true,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lotNo",
        width: "20%",
        align: "left",
        sortable: true,
      },
      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "quantity",
        width: "10%",
        align: "center",
        sortable: true,
      },
      // 入荷元/出荷先
      {
        text: i18n.tc("label.lbl_recieveShipTo") + "/" + i18n.tc("label.lbl_deliveryDestination"),
        value: "recieveShipTo",
        width: "15%",
        align: "left",
        sortable: true,
      },
    ],
    detailDialogData: {
      clientSid: "",
      clientName: "",
      itemManageNo: "",
    },
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },

    fromDate: "",
    fromDateCal: "",
    // 出荷日to
    toDate: "",
    toDateCal: "",
    menuToDate: false,
    menuFromDate: false,
    itemSearch: "",
    userItemSearch: "",
    inputList: [],
    productHint: "",
    // セッション保持用
    deploySession: "",
    slipNoSession: "",
    fromDateSession: "",
    toDateSession: "",
    suppliersSession: "",
    itemSession: "",
    itemListSession: [],
    lotNoSession: "",
    serialSession: "",
    dueDateSession: "",
    lotSubkey1Session: "",
    lotSubkey2Session: "",
    userSuppliersSession: "",
    userItemSession: "",
    userItemListSession: [],
    userLotNoSession: "",
    userSerialSession: "",
    locationSession: "",
    managerSession: "",
    placeSession: "",
    managementNoSession: "",
    managementNoOrgSession: "",
    placeNameSession: "",
    // URL発行
    returnURL: "",
    //チェック上限値
    ckeckLimit: 0,
    transitionItem: 0,
    transitionUserItem: 0,
    productUserHint: "",
    //初期値
    initial: 0,
    rules: {
      inputRequired: (value1, value2) => !!value1 || !!value2 || i18n.tc("check.chk_inputfromto"),
      yyyymmdd: (value) =>
        !value ||
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      clientRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      deployDirectionRequired: (value) => !!value || i18n.tc("check.chk_inputDeployDirection"),
      inputToTimeCheck: (value1, value2) =>
        !value2 ||
        !value1 ||
        !(
          dateTimeHelper.validDate(commonUtil.zen2han(value2), "/") && dateTimeHelper.isDate(value2)
        ) ||
        new Date(value1).getTime() >= new Date(value2).getTime() ||
        i18n.tc("check.chk_toDate"),
      inputFromTimeCheck: (value1, value2) =>
        !value1 ||
        !value2 ||
        !(
          dateTimeHelper.validDate(commonUtil.zen2han(value1), "/") && dateTimeHelper.isDate(value1)
        ) ||
        new Date(value1).getTime() >= new Date(value2).getTime() ||
        i18n.tc("check.chk_toFrom"),
    },
    getSuppliersSelected: "",
    productHintArrival: "",
    // 遷移フラグ
    transitionFlg: false,
    transitionFlg1: false,
  }),
  methods: {
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 100;
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.btnSearch("get-search");
      }
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.btnSearch("get-search");
      }
    },

    // 初期化
    init() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      if (this.$route.params.name == appConfig.SCREEN_ID.P_MNG_011) {
        this.deploySelected = this.$route.params.deploymentDiv;
        if (this.$route.params.deploymentDiv == "03") {
          this.userDisabledFlg = false;
        }
        this.slipNo = this.$route.params.listNo;
        this.fromDate = this.$route.params.actualDateFrom;
        if (this.$route.params.actualDateFrom != "") {
          this.fromDateCal = dateTimeHelper.dateCalc(this.$route.params.actualDateFrom);
        }
        this.toDate = this.$route.params.actualDateTo;
        if (this.$route.params.actualDateTo != "") {
          this.toDateCal = dateTimeHelper.dateCalc(this.$route.params.actualDateTo);
        }
        this.suppliersSelected = this.$route.params.clientSid; //メーカー取引先
        this.itemSelected = this.$route.params.itemStandardSid; //メーカー品番/品名
        this.itemList = this.$route.params.itemListsession; //メーカー品番リスト
        this.lotNo = this.$route.params.lotNo; //ロット(メーカー)
        this.serial = this.$route.params.serial; //S/N(メーカー)
        this.dueDate = this.$route.params.dueDate; //期限日(メーカー)
        this.lotSubkey1 = this.$route.params.lotSubkey1; //付属キー１(メーカー)
        this.lotSubkey2 = this.$route.params.lotSubkey2; //付属キー２(メーカー)
        this.userSuppliersSelected = this.$route.params.userClientSid; //ユーザー取引先
        this.userItemSelected = this.$route.params.userItemStandardSid; //ユーザー品番/品名
        this.userItemList = this.$route.params.userItemListsession; //ユーザー品番リスト
        this.userLotNo = this.$route.params.userLotNo; //ロット(ユーザー)
        this.userSerial = this.$route.params.userSerial; //S/N(ユーザー)
        this.locationSelected = this.$route.params.location; //ロケーション
        this.managerSelected = this.$route.params.manager; //担当者
        this.place = this.$route.params.place; //場所
        this.managementNo = this.$route.params.managementNo; //管理No.
        this.managementNoOrg = this.$route.params.managementNoOrg; //管理No.(元)
        this.placeName = this.$route.params.placeName; //場所名
        this.transitionFlg = true;
        this.transitionFlg1 = true;
        this.btnSearchPost("get-search");
      } else {
        this.disableBtn = true;
        this.fromDate = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.fromDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.toDate = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.toDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        //場所セット
        this.placeName =
          i18n.tc("label.lbl_recieveShipTo") + "/" + i18n.tc("label.lbl_deliveryDestination");
      }
      this.getInitData();
    },
    // 初期データ取得
    getInitData() {
      // 展開方向
      const deployList = getParameter.getCodeMst(appConfig.CODETYPE.DEPLOY_DIRECTION);
      // メーカー取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(null)
      );
      //チェック上限
      const limitList = getParameter.getCodeMst(appConfig.CODETYPE.CHECK_LIMIT);
      // 担当者
      const userBizList = getParameter.getUserBiz();

      Promise.all([deployList, clientList, limitList, userBizList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.deployList = result[0];
          this.supplierList = result[1];
          this.checLlimitList = result[2];
          this.managerList = result[3];
          //上限値設定
          for (var i = 0; i < this.checLlimitList.length; i++) {
            if (this.checLlimitList[i].value == "01") {
              this.ckeckLimit = this.checLlimitList[i].text;
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          if (this.$route.params.name != appConfig.SCREEN_ID.P_MNG_011) {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          }
        });

      if (this.$route.params.name == appConfig.SCREEN_ID.P_MNG_011) {
        this.itemList = this.$route.params.itemListsession;
      }
    },

    /**
     * メーカー取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * ユーザー取引先コードリストボックス取得処理
     */
    setUserSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.userSuppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    // メーカー品名セット処理
    setItemHint() {
      for (var i = 0; i < this.itemList.length; i++) {
        if (this.itemList[i].value == this.itemSelected) {
          return this.itemList[i].name;
        }
      }
    },

    // ユーザー品名セット処理
    setUserItemHint() {
      for (var i = 0; i < this.userItemList.length; i++) {
        if (this.userItemList[i].value == this.userItemSelected) {
          return this.userItemList[i].name;
        }
      }
    },

    // メーカー品番/品名を選択時
    changeProductNm(value) {
      let val = this.itemList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.itemSearch = val.text;
      }
      this.itemList = this.itemList.filter((v) => v.value == value);
    },

    // ユーザー品番/品名を選択時
    changeUserProductNm(value) {
      let val = this.userItemList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productUserHint = val.text;
        this.userItemSearch = val.text;
      }
      this.userItemList = this.userItemList.filter((v) => v.value == value);
    },

    // From前日
    preDateOfFromDate() {
      if (this.fromDateCal == null) {
        return;
      }
      let date = new Date(this.fromDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.fromDateCal != toDate) {
        this.fromDateCal = toDate;
      } else {
        this.fromDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    // From翌日
    nextDateOfFromDate() {
      if (this.fromDateCal == null) {
        return;
      }
      let date = new Date(this.fromDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.fromDateCal != toDate) {
        this.fromDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.fromDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    // To前日
    preDateOfToDate() {
      if (this.toDateCal == null) {
        return;
      }
      let date = new Date(this.toDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.toDateCal != toDate) {
        this.toDateCal = toDate;
      } else {
        this.toDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    // To翌日
    nextDateOfToDate() {
      if (this.toDateCal == null) {
        return;
      }
      let date = new Date(this.toDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.toDateCal != toDate) {
        this.toDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.toDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力(From)
    changeFromDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.fromDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.fromDateCal = null;
      }
    },
    // 日付入力(To)
    changeToDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.toDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.toDateCal = null;
      }
    },

    // 検索ボタン
    btnSearch(val) {
      //バリデーションチェック
      if (this.$refs.form.validate()) {
        this.btnSearchPost(val);
      }
    },
    btnSearchPost(val) {
      // 指示選択以外に入力されているかのチェック用
      var check = 0;
      var now = new Date();
      this.today = dateTimeHelper.toStringDate("YYYY/MM/DD", now);
      this.nowTime = dateTimeHelper.toStringDate("hh:mm", now);

      const config = this.$httpClient.createRequestBodyConfig();

      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MNG_010;

      // ローディング画面表示ON
      this.loadingCounter = 1;

      //セッションへ保持
      this.deploySession = this.deploySelected; //指示選択
      this.slipNoSession = this.slipNo; //伝票No
      this.fromDateSession = this.fromDate; //実績日(From)
      this.toDateSession = this.toDate; //実績日(To)
      this.suppliersSession = this.suppliersSelected; //メーカー取引先
      this.itemSession = this.itemSelected; //メーカー品番/品名
      this.itemListSession = this.itemList; //メーカー品番リスト
      this.lotNoSession = this.lotNo; //ロット(メーカー)
      this.serialSession = this.serial; //S/N(メーカー)
      this.dueDateSession = this.dueDate; //期限日(メーカー)
      this.lotSubkey1Session = this.lotSubkey1; //付属キー１(メーカー)
      this.lotSubkey2Session = this.lotSubkey2; //付属キー２(メーカー)
      this.userSuppliersSession = this.userSuppliersSelected; //ユーザー取引先
      this.userItemSession = this.userItemSelected; //ユーザー品番/品名
      this.userItemListSession = this.userItemList; //ユーザー品番リスト
      this.userLotNoSession = this.userLotNo; //ロット(ユーザー)
      this.userSerialSession = this.userSerial; //S/N(ユーザー)
      this.locationSession = this.locationSelected; //ロケーション
      this.managerSession = this.managerSelected; //担当者
      this.placeSession = this.place; //場所
      this.managementNoSession = this.managementNo; //管理No.
      this.managementNoOrgSession = this.managementNoOrg; //管理No.元
      this.placeNameSession = this.placeName; //場所（入荷元/出荷先/搬入先）

      // 指示選択
      config.reqIdv.deploymentDiv = this.deploySelected;
      // 伝票No
      if (this.slipNo) {
        config.reqIdv.listNo = this.slipNo;
        check++;
      }
      // 実績日（From）
      if (this.fromDate) {
        let dateRange = this.fromDate + " 00:00:00.000";
        config.reqIdv.actualDateFrom = dateTimeHelper.convertUTC(dateRange);
        check++;
      }
      // 実績日（To）
      if (this.toDate) {
        let dateRange = this.toDate + " 23:59:59.999";
        config.reqIdv.actualDateTo = dateTimeHelper.convertUTC(dateRange);
        check++;
      }
      // メーカー取引先SID
      if (this.suppliersSelected) {
        config.reqIdv.clientSid = this.suppliersSelected;
        check++;
      }
      // 商品標準SID
      if (this.itemSelected) {
        config.reqIdv.itemStandardSid = this.itemSelected;
        check++;
      }
      // ロット(メーカー)
      if (this.lotNo) {
        config.reqIdv.lotNo = this.lotNo;
        check++;
      }
      // S/N(メーカー)
      if (this.serial) {
        config.reqIdv.serial = this.serial;
        check++;
      }
      // 期限日(メーカー)
      if (this.dueDate) {
        config.reqIdv.dueDate = this.dueDate;
        check++;
      }
      // 付属キー１(メーカー)
      if (this.lotSubkey1) {
        config.reqIdv.lotSubkey1 = this.lotSubkey1;
        check++;
      }
      // 付属キー２(メーカー)
      if (this.lotSubkey2) {
        config.reqIdv.lotSubkey2 = this.lotSubkey2;
        check++;
      }
      // ユーザー取引先SID
      if (this.userSuppliersSelected) {
        config.reqIdv.userClientSid = this.userSuppliersSelected;
        check++;
      }
      // ユーザー品番SID
      if (this.userItemSelected) {
        config.reqIdv.userItemSid = this.userItemSelected;
        check++;
      }
      // ロット(ユーザー)
      if (this.userLotNo) {
        config.reqIdv.userLotNo = this.userLotNo;
        check++;
      }
      // S/N(ユーザー)
      if (this.userSerial) {
        config.reqIdv.userSerialNo = this.userSerial;
        check++;
      }
      // ロケ
      if (this.locationSelected) {
        config.reqIdv.locationNo = this.locationSelected;
        check++;
      }
      // 担当者
      if (this.managerSelected) {
        config.reqIdv.userSid = this.managerSelected;
        check++;
      }
      // 関係先
      if (this.place) {
        config.reqIdv.place = this.place;
        check++;
      }
      // 管理No.
      if (this.managementNo) {
        config.reqIdv.itemManageNo = this.managementNo;
        check++;
      }
      // 管理No.元
      if (this.managementNoOrg) {
        config.reqIdv.itemManageNoOrg = this.managementNoOrg;
        check++;
      }

      //指示選択以外入力されていない場合
      if (check <= 0) {
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.message = messsageUtil.getMessage("P-MNG-010_002_E", [
          i18n.tc("btn.btn_search"),
        ]);

        // ローディング画面表示OFF
        this.loadingCounter = 0;
        return;
      }
      // ソート
      if (this.sortItem != "") {
        config.reqIdv.sortItem = this.sortItem;
      }

      // ページャー処理
      if (val == "get-search") {
        config.reqIdv.pageIndex = 1;
        this.page = 1;
      } else {
        // ページャー処理
        if (this.page >= 2 && this.inputList.length == 0) {
          config.reqIdv.pageIndex = 1;
          this.page = 1;
        } else {
          config.reqIdv.pageIndex = this.page;
        }
      }

      // ページング
      config.reqIdv.paginationFlg = "1";
      config.reqIdv.pageLimit = this.itemsPerPage;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_TRACE_SEARCH, config, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              const list = [];
              //headerItems内削除
              this.headerItems.splice(0, this.headerItems.length);
              //headerItems設定
              this.tableDate();
              if (this.deploySelected == "01" || this.deploySelected == "02") {
                this.headerItems.push({
                  // メーカー品番
                  text: i18n.tc("label.lbl_makerProductCdName"),
                  value: "productCode",
                  width: "25%",
                  align: "left",
                  sortable: true,
                });
              } else {
                this.headerItems.push({
                  // ユーザー品番
                  text: i18n.tc("label.lbl_userProductCdName"),
                  value: "productCode",
                  width: "25%",
                  align: "left",
                  sortable: true,
                });
              }
              this.tableDate1();

              if (this.deploySelected == "01") {
                this.headerItems.push({
                  // 入荷先
                  text: i18n.tc("label.lbl_arrival"),
                  value: "recieveShipTo",
                  width: "15%",
                  align: "left",
                  sortable: true,
                });
              } else if (this.deploySelected == "02") {
                this.headerItems.push({
                  // 出荷先
                  text: i18n.tc("label.lbl_shippingTo"),
                  value: "recieveShipTo",
                  width: "15%",
                  align: "left",
                  sortable: true,
                });
              } else {
                this.headerItems.push({
                  // 搬入先
                  text: i18n.tc("label.lbl_deliveryDestination"),
                  value: "recieveShipTo",
                  width: "15%",
                  align: "center",
                  sortable: true,
                });
              }
              jsonData.resIdv.traceSearch.forEach((row) => {
                let lotNoObj = commonUtil.convertLotNo(row.lotNo);
                list.push({
                  // 日付
                  date: dateTimeHelper.convertUTC2JST(row.processDate).substr(0, 10),
                  // 品番
                  productCode: row.itemCd.substring(0, 50).trimRight(),
                  // 品番SID
                  itemSid: row.itemSid,
                  //付帯品番
                  accessoryProduct: row.itemCd.slice(-50).trimRight(),
                  // 品名
                  productCodeName: row.itemName,
                  // ロット + S/N
                  lotNo: lotNoObj.lot,
                  //期限日 + 付属キー１ + 付属キー２
                  dueDate: lotNoObj.dueDate,
                  // 数量
                  quantity: commonUtil.formatToCurrency(Number(row.stock ?? 0)),
                  // 入荷元/出荷先/搬入先
                  recieveShipTo: row.deliveryCd,
                  deliveryName: row.deliveryName,
                  // 管理No
                  managementNo: row.itemManageNoList,
                });
              });
              this.inputList = list;
              resolve(response);

              // 0件の場合は、ボタンを非活性にする
              if (this.inputList.length == 0) {
                this.disableBtn = true;
              } else {
                this.disableBtn = false;
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.inputList = [];
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    tableDate() {
      this.headerItems.push(
        // チェックボックス
        {
          value: "check",
          width: "3%",
          align: "center",
          sortable: false,
        },
        // 日付
        {
          text: i18n.tc("label.lbl_date"),
          value: "date",
          width: "7%",
          align: "center",
          sortable: true,
        }
      );
    },

    tableDate1() {
      this.headerItems.push(
        // 付帯品番
        {
          text: i18n.tc("label.lbl_accessoryProduct"),
          value: "accessoryProduct",
          width: "15%",
          align: "left",
          sortable: true,
        },
        // ロット/S/N
        {
          text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
          value: "lotNo",
          width: "20%",
          align: "left",
          sortable: true,
        },
        // 数量
        {
          text: i18n.tc("label.lbl_quantity"),
          value: "quantity",
          width: "10%",
          align: "center",
          sortable: true,
        }
      );
    },

    // 責任区分の選択チェック
    checkBlameDiv() {
      if (this.blameSelected == null || this.blameSelected == "") {
        this.alertMessage = i18n.tc("check.chk_inputblameDiv");
      } else {
        this.alertMessage = "";
      }
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    alertCancel() {
      this.alertDialog = false;
    },

    // トレース展開画面へ
    openTraceDeploy() {
      if (this.$refs.form.validate()) {
        // チェックした行
        const checkList = [];
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].check) {
            checkList.push(this.inputList[i]);
          }
        }
        // １つもチェックされていない場合、画面遷移できない
        if (checkList.length == 0) {
          this.alertDialog = true;
          this.checkMessage = messsageUtil.getMessage("P-EXT-003_001_E");
          return;
        }
        // コードマスタの条件値以上チェックされている場合
        else if (checkList.length > this.ckeckLimit) {
          this.alertDialog = true;
          this.checkMessage = messsageUtil.getMessage("P-MNG-010_001_E", [this.ckeckLimit]);
          return;
        } else {
          // 指示Noの設定
          for (let i = 0; i < checkList.length; i++) {
            checkList[i].itemManageNo = i + 1;
            for (var t = 0; t < checkList[i].managementNo.length; t++) {
              //重複チェック
              this.checkedList.push(checkList[i].managementNo[t]);
            }
          }

          // 画面遷移
          this.$router.push({
            name: appConfig.SCREEN_ID.P_MNG_011,
            params: {
              checkList: this.checkedList,
              // 検索条件を渡す
              deploymentDiv: this.deploySession ?? "", //指示選択
              listNo: this.slipNoSession ?? "", //伝票No
              actualDateFrom: this.fromDateSession ?? "", //日付From
              actualDateTo: this.toDateSession ?? "", //日付To
              clientSid: this.suppliersSession ?? "", //取引先(メーカー)
              itemStandardSid: this.itemSession ?? "", //メーカー品番/品名
              itemListsession: this.itemListSession, //メーカー品番リスト
              lotNo: this.lotNoSession ?? "", //ロット(メーカー)
              serial: this.serialSession ?? "", //S/N(メーカー)
              dueDate: this.dueDateSession ?? "", //期限日(メーカー)
              lotSubkey1: this.lotSubkey1Session ?? "", //付属キー１(メーカー)
              lotSubkey2: this.lotSubkey2Session ?? "", //付属キー２(メーカー)
              userClientSid: this.userSuppliersSession ?? "", //取引先(ユーザー)
              userItemStandardSid: this.userItemSession ?? "", //ユーザー品番/品名
              userItemListsession: this.userItemListSession, //ユーザー品番リスト
              userLotNo: this.userLotNoSession ?? "", //ロット(ユーザー)
              userSerial: this.userSerialSession ?? "", //S/N(ユーザー)
              location: this.locationSession ?? "", //ロケーション
              relatedPartySid: this.arrivalsession ?? "", //入荷元/出荷先
              manager: this.managerSession ?? "", //担当者
              place: this.placeSession ?? "", //場所
              managementNo: this.managementNoSession ?? "", //管理No
              managementNoOrg: this.managementNoOrgSession ?? "", //管理No.元
              placeName: this.placeNameSession ?? "", //場所（入荷元/出荷先/搬入先）
            },
          });
        }
      }
    },
    /**
     * 隠す押下時の処理
     */
    btnDisiplay() {
      this.searchStyle = "display: none;";
      this.reStyle = "";
    },
    /**
     * 再表示押下時の処理
     */
    btnReDisiplay() {
      this.searchStyle = "";
      this.reStyle = "display: none;";
    },
    /**
     * 指示選択変更時
     */
    deploySelectedCheck() {
      this.userDisabledFlg = true;
      if (this.deploySelected == "01") {
        this.placeName = i18n.tc("label.lbl_arrival");
      } else if (this.deploySelected == "02") {
        this.placeName = i18n.tc("label.lbl_shippingTo");
      } else if (this.deploySelected == "03") {
        this.placeName = i18n.tc("label.lbl_deliveryDestination");
        this.userDisabledFlg = false;
      } else {
        this.placeName =
          i18n.tc("label.lbl_recieveShipTo") + "/" + i18n.tc("label.lbl_deliveryDestination");
      }
      this.userSuppliersSelected = "";
      this.userItemSelected = "";
      this.userLotNo = "";
      this.userSerial = "";
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      this.headerItems.splice(0, this.headerItems.length);
      this.headerItems.push(
        // チェックボックス
        {
          value: "check",
          width: "3%",
          align: "center",
          sortable: false,
        },
        // 日付
        {
          text: i18n.tc("label.lbl_date"),
          value: "date",
          width: "7%",
          align: "center",
          sortable: true,
        },
        // 品番
        {
          text:
            i18n.tc("label.lbl_makerProductCd") +
            "/" +
            i18n.tc("label.lbl_userProductCd") +
            "/" +
            i18n.tc("label.lbl_productCodeName"),
          value: "productCode",
          width: "20%",
          align: "left",
          sortable: true,
        },
        // 付帯品番
        {
          text: i18n.tc("label.lbl_accessoryProduct"),
          value: "accessoryProduct",
          width: "15%",
          align: "left",
          sortable: true,
        },
        // ロット
        {
          text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
          value: "lotNo",
          width: "20%",
          align: "left",
          sortable: true,
        },
        // 数量
        {
          text: i18n.tc("label.lbl_quantity"),
          value: "quantity",
          width: "10%",
          align: "center",
          sortable: true,
        },
        // 入荷元/出荷先
        {
          text: i18n.tc("label.lbl_recieveShipTo") + "/" + i18n.tc("label.lbl_deliveryDestination"),
          value: "recieveShipTo",
          width: "15%",
          align: "left",
          sortable: true,
        }
      );
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      this.$route.params.name = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
    /**
     * URL発行
     */
    link() {
      location.href = this.returnURL;
    },

    // CSV出力
    traceSearchCsv() {
      //バリデーションチェック
      if (this.$refs.form.validate()) {
        // 指示選択以外に入力されているかのチェック用
        var check = 0;
        // ローディング画面表示ON
        this.loadingCounter = 1;

        const config = this.$httpClient.createRequestBodyConfig();
        // 共通IF項目 画面ID
        config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MNG_010;

        // 指示選択
        config.reqIdv.deploymentDiv = this.deploySelected;
        // 伝票No
        if (this.slipNo) {
          config.reqIdv.listNo = this.slipNo;
          check++;
        }
        // 実績日（From）
        if (this.fromDate) {
          let dateRange = this.fromDate + " 00:00:00.000";
          config.reqIdv.actualDateFrom = dateTimeHelper.convertUTC(dateRange);
          check++;
        }
        // 実績日（To）
        if (this.toDate) {
          let dateRange = this.toDate + " 23:59:59.999";
          config.reqIdv.actualDateTo = dateTimeHelper.convertUTC(dateRange);
          check++;
        }
        // メーカー取引先SID
        if (this.suppliersSelected) {
          config.reqIdv.clientSid = this.suppliersSelected;
          check++;
        }
        // 商品標準SID
        if (this.itemSelected) {
          config.reqIdv.itemStandardSid = this.itemSelected;
          check++;
        }
        // ロット(メーカー)
        if (this.lotNo) {
          config.reqIdv.lotNo = this.lotNo;
          check++;
        }
        // S/N(メーカー)
        if (this.serial) {
          config.reqIdv.serial = this.serial;
          check++;
        }
        // 期限日(メーカー)
        if (this.dueDate) {
          config.reqIdv.dueDate = this.dueDate;
          check++;
        }
        // 付属キー１(メーカー)
        if (this.lotSubkey1) {
          config.reqIdv.lotSubkey1 = this.lotSubkey1;
          check++;
        }
        // 付属キー２(メーカー)
        if (this.lotSubkey2) {
          config.reqIdv.lotSubkey2 = this.lotSubkey2;
          check++;
        }
        // ユーザー取引先SID
        if (this.userSuppliersSelected) {
          config.reqIdv.userClientSid = this.userSuppliersSelected;
          check++;
        }
        // ユーザー品番SID
        if (this.userItemSelected) {
          config.reqIdv.userItemSid = this.userItemSelected;
          check++;
        }
        // ロット(ユーザー)
        if (this.userLotNo) {
          config.reqIdv.userLotNo = this.userLotNo;
          check++;
        }
        // S/N(ユーザー)
        if (this.userSerial) {
          config.reqIdv.userSerialNo = this.userSerial;
          check++;
        }
        // ロケ
        if (this.locationSelected) {
          config.reqIdv.locationNo = this.locationSelected;
          check++;
        }
        // 担当者
        if (this.managerSelected) {
          config.reqIdv.userSid = this.managerSelected;
          check++;
        }
        // 関係先
        if (this.place) {
          config.reqIdv.place = this.place;
          check++;
        }
        // 管理No.
        if (this.managementNo) {
          config.reqIdv.itemManageNo = this.managementNo;
          check++;
        }

        // 管理No.元
        if (this.managementNoOrg) {
          config.reqIdv.itemManageNoOrg = this.managementNoOrg;
          check++;
        }

        //指示選択以外入力されていない場合
        if (check <= 0) {
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.message = messsageUtil.getMessage("P-MNG-010_002_E", [
            i18n.tc("btn.btn_outputcsv"),
          ]);

          // ローディング画面表示OFF
          this.loadingCounter = 0;
          return;
        }

        // ソート
        if (this.sortItem != "") {
          config.reqIdv.reqComSortItem = this.sortItem;
        }

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_TRACE_SEARCH_FILE, config, appConfig.APP_CONFIG)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 成功
              if (jsonData.resCom.resComCode == "000") {
                // 返却されたurlをデータに格納、ダウンロードボタンの活性化
                this.returnURL = jsonData.resIdv.filePath;
                this.link();
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
  },
  created() {
    this.init();
  },
  computed: {},
  watch: {
    //メーカー取引先変更時
    suppliersSelected() {
      if (!this.transitionFlg) {
        //メーカー品番/品名クリア
        this.itemSelected = "";
        this.itemList = [];
      }
    },
    //ユーザー取引先変更時
    userSuppliersSelected() {
      if (!this.transitionFlg1) {
        //ユーザー品番/品名クリア
        this.userItemSelected = "";
        this.userItemList = [];
      }
    },
    fromDateCal(val) {
      this.fromDate = this.formatDate(val);
    },
    toDateCal(val) {
      this.toDate = this.formatDate(val);
    },
    fromDate(val) {
      this.fromDate = this.formatDate(val);
    },
    toDate(val) {
      this.toDate = this.formatDate(val);
    },

    dialog(val) {
      val || this.close();
    },

    page: function (newValue) {
      this.page = newValue;
      this.btnSearch("");
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          if (this.deploySelected == "01") {
            if (this.sortItem != appConfig.P_MNG_010_SORT_IN[sortBy].replaceAll("{0}", ascdesc)) {
              this.sortItem = appConfig.P_MNG_010_SORT_IN[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
              this.btnSearch("");
            }
          } else if (this.deploySelected == "02" || this.deploySelected == "03") {
            if (this.sortItem != appConfig.P_MNG_010_SORT_OUT[sortBy].replaceAll("{0}", ascdesc)) {
              this.sortItem = appConfig.P_MNG_010_SORT_OUT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
              this.btnSearch("");
            }
          }
        }
      },
      //deep: true,
    },
    // 品番と品名での検索
    itemSearch(val) {
      if (this.$route.params.name == appConfig.SCREEN_ID.P_MNG_011 && this.transitionItem == 0) {
        this.transitionItem = 1;
      } else {
        if (val ?? false) {
          // 取引先が選択されてない場合、処理しない
          if (this.suppliersSelected == null || this.suppliersSelected == "") {
            return;
          }
          // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
          if (val == this.productHint) {
            return;
          }
          // 遷移後
          if (this.transitionFlg) {
            this.transitionFlg = false;
            return;
          }
          // 入力桁数が定義した数異常の場合検索処理
          if (val.length >= appConfig.CNTCHARITEM) {
            const itemsBiz = getParameter.getItemsBizAddName2(
              this.suppliersSelected,
              dateTimeHelper.convertUTC(),
              val
            );

            Promise.all([itemsBiz])
              .then((result) => {
                // 画面の初期値を設定します。
                const list = [];
                this.itemList = list.concat(result[0]);
                this.itemList.unshift({ name: "", text: "", value: "" });
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          } else {
            this.itemList.length = 0;
          }
        }
      }
    },
    userItemSearch(val) {
      if (
        this.$route.params.name == appConfig.SCREEN_ID.P_MNG_011 &&
        this.transitionUserItem == 0
      ) {
        this.transitionUserItem = 1;
      } else {
        if (val ?? false) {
          // 取引先が選択されてない場合、処理しない
          if (this.userSuppliersSelected == null || this.userSuppliersSelected == "") {
            return;
          }
          // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
          if (val == this.productUserHint) {
            return;
          }
          // 遷移後
          if (this.transitionFlg1) {
            this.transitionFlg1 = false;
            return;
          }
          // 入力桁数が定義した数異常の場合検索処理
          if (val.length >= appConfig.CNTCHARITEM) {
            const itemsBiz = getParameterProcurement.getUserItemsBizCdAddName(
              this.userSuppliersSelected,
              val
            );

            Promise.all([itemsBiz])
              .then((result) => {
                // 画面の初期値を設定します。
                const list = [];
                this.userItemList = list.concat(result[0]);
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          } else {
            this.itemList.length = 0;
          }
        }
      }
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.overflow {
  overflow: hidden;
}

.link {
  text-decoration: none;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper {
  max-height: 530px;
}

//ソート非表示
#listData.v-data-table--fixed-header
  ::v-deep
  .v-data-table__wrapper
  table
  thead
  tr
  th[aria-sort="none"]
  .v-icon {
  display: none;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr {
  height: 60px;
}
</style>
