export default class BarcodeUtil {
  // バーコード描画・表示の大きさの基準値です。
  sizeReference = 3;

  // バーコード画像の横幅の基準値です。
  widthReference = 13;

  // バーコード画像の高さの基準値です。
  heightReference = 50;

  // 文字の割り当てです。
  code = [
    // 数値です。
    { character: "0", bar: [1, 0, 1, 0, 0, 1, 1, 0, 1, 1, 0, 1] },
    { character: "1", bar: [1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1] },
    { character: "2", bar: [1, 0, 1, 1, 0, 0, 1, 0, 1, 0, 1, 1] },
    { character: "3", bar: [1, 1, 0, 1, 1, 0, 0, 1, 0, 1, 0, 1] },
    { character: "4", bar: [1, 0, 1, 0, 0, 1, 1, 0, 1, 0, 1, 1] },
    { character: "5", bar: [1, 1, 0, 1, 0, 0, 1, 1, 0, 1, 0, 1] },
    { character: "6", bar: [1, 0, 1, 1, 0, 0, 1, 1, 0, 1, 0, 1] },
    { character: "7", bar: [1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1] },
    { character: "8", bar: [1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1] },
    { character: "9", bar: [1, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0, 1] },
    // アルファベットです。
    { character: "A", bar: [1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 1] },
    { character: "B", bar: [1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1] },
    { character: "C", bar: [1, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1] },
    { character: "D", bar: [1, 0, 1, 0, 1, 1, 0, 0, 1, 0, 1, 1] },
    { character: "E", bar: [1, 1, 0, 1, 0, 1, 1, 0, 0, 1, 0, 1] },
    { character: "F", bar: [1, 0, 1, 1, 0, 1, 1, 0, 0, 1, 0, 1] },
    { character: "G", bar: [1, 0, 1, 0, 1, 0, 0, 1, 1, 0, 1, 1] },
    { character: "H", bar: [1, 1, 0, 1, 0, 1, 0, 0, 1, 1, 0, 1] },
    { character: "I", bar: [1, 0, 1, 1, 0, 1, 0, 0, 1, 1, 0, 1] },
    { character: "J", bar: [1, 0, 1, 0, 1, 1, 0, 0, 1, 1, 0, 1] },
    { character: "K", bar: [1, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 1] },
    { character: "L", bar: [1, 0, 1, 1, 0, 1, 0, 1, 0, 0, 1, 1] },
    { character: "M", bar: [1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 0, 1] },
    { character: "N", bar: [1, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 1] },
    { character: "O", bar: [1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1] },
    { character: "P", bar: [1, 0, 1, 1, 0, 1, 1, 0, 1, 0, 0, 1] },
    { character: "Q", bar: [1, 0, 1, 0, 1, 0, 1, 1, 0, 0, 1, 1] },
    { character: "R", bar: [1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 0, 1] },
    { character: "S", bar: [1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 0, 1] },
    { character: "T", bar: [1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 0, 1] },
    { character: "U", bar: [1, 1, 0, 0, 1, 0, 1, 0, 1, 0, 1, 1] },
    { character: "V", bar: [1, 0, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1] },
    { character: "W", bar: [1, 1, 0, 0, 1, 1, 0, 1, 0, 1, 0, 1] },
    { character: "X", bar: [1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1] },
    { character: "Y", bar: [1, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 1] },
    { character: "Z", bar: [1, 0, 0, 1, 1, 0, 1, 1, 0, 1, 0, 1] },
    // 記号です。
    { character: "-", bar: [1, 0, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1] },
    { character: ".", bar: [1, 1, 0, 0, 1, 0, 1, 0, 1, 1, 0, 1] },
    { character: " ", bar: [1, 0, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1] },
    { character: "$", bar: [1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1] },
    { character: "/", bar: [1, 0, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1] },
    { character: "+", bar: [1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1] },
    { character: "%", bar: [1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1] },
    { character: "*", bar: [1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1] },
  ];

  // バーコード画像を生成します。
  getImage(value) {
    // キャンバスを生成します。
    let canvas = document.createElement("canvas");

    // キャンバスのコンテキストを取得します。
    let context = canvas.getContext("2d");

    // キャンバス描画エリアの横幅を設定します。
    canvas.width = value.length * this.sizeReference * this.widthReference + this.sizeReference * 3;

    // キャンバス描画エリアの高さを設定します。
    canvas.height = this.sizeReference * this.heightReference;

    // キャンバス表示サイズの横幅を設定します。
    canvas.style.width =
      value.length * this.sizeReference * this.widthReference + this.sizeReference * 3 + "px";

    // キャンバス表示サイズの高さを設定します。
    canvas.style.height = this.sizeReference * this.heightReference + "px";

    // 塗りつぶし色を設定します。
    context.fillStyle = "rgba(255,255,255,1.0)";

    // 長方形の位置とサイズを設定します。
    context.fillRect(0, 0, canvas.width, canvas.height);

    // バーコード値の各文字に対してループします。
    for (let i = 0; i < value.length; i++) {
      // i番目の文字を取得します。
      let character = value.substr(i, 1);

      // 新たなインデックスを生成します。
      let j = 0;

      // i番目の文字の文字割り当て内のインデックスを取得します。
      for (let k = 0; k < this.code.length; k++) {
        // k番目の文字がi番目の文字と一致する場合
        if (this.code[k].character == character) {
          // jにkを代入します。
          j = k;

          // ループを抜けます。
          break;
        }
      }

      // i番目の文字を表すバーコードを描画します。
      for (let k = 0; k < 12; k++) {
        // 文字割り当てのバー記入部に対して
        if (this.code[j].bar[k] == 1) {
          // コンテキストのパスをリセットします。
          context.beginPath();

          // 塗りつぶし色を設定します。
          context.fillStyle = "rgba(0,0,0,1.0)";

          // 長方形を生成します。
          context.rect(
            // 水平方向の描画開始位置です。
            this.sizeReference * 2 + (i * this.widthReference + k) * this.sizeReference,

            // 鉛直方向の描画開始位置です。
            this.sizeReference * 2,

            // 描画するバーの横幅です。
            this.sizeReference,

            // 描画するバーの高さです。
            this.sizeReference * this.heightReference
          );

          // 長方形を塗りつぶします。
          context.fill();
        }
      }
    }

    // 描画したバーコードのデータURLを返します。
    return canvas.toDataURL("image/png");
  }
}

export const barcodeUtil = new BarcodeUtil();
