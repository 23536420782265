<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_traceDeploy" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row style="margin-top: 1rem">
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン-->
                <v-btn color="primary" class="api-btn" @click="backPage">
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          class="tableData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          disable-sort
          disable-pagination
          :hide-default-footer="true"
          height="100%"
        >
          <!-- 品番(ヘッダ) -->
          <template v-slot:[`header.itemCd`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div>{{ $t("label.lbl_productCodeName") }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- ロット(ヘッダ) -->
          <template v-slot:[`header.lotNo`]="{ header }">
            <span>
              <div>{{ header.text }}</div>
              <span>
                {{
                  $t("label.lbl_dueDate") +
                  "／" +
                  $t("label.lbl_lotSubkey1") +
                  "／" +
                  $t("label.lbl_lotSubkey2")
                }}
              </span>
            </span>
          </template>
          <!-- ユーザー品番(ヘッダ) -->
          <template v-slot:[`header.userProductCd`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div>{{ "ユ" + $t("label.lbl_productCodeName") }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 日付 -->
          <template v-slot:[`item.opeHistDate`]="{ item }">
            <v-row :class="item.setClass">
              <v-col style="padding: 0 12px">
                <div>{{ item.opeHistDate }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 画面名 -->
          <template v-slot:[`item.opeDispName`]="{ item }">
            <v-row :class="item.setClass">
              <v-col style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="width: 220px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.opeDispName }}
                    </div>
                  </template>
                  <div>{{ item.opeDispName }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- 工程 -->
          <template v-slot:[`item.procName`]="{ item }">
            <v-row :class="item.setClass">
              <v-col style="padding: 0 12px">
                <div>{{ item.procName }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 品番-->
          <template v-slot:[`item.itemCd`]="{ item }">
            <v-row :class="item.setClass">
              <v-col style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="width: 200px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.itemCd }}<br />
                      {{ item.itemName }}
                    </div>
                  </template>
                  <div>{{ item.itemCd }}<br />{{ item.itemName }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- 付帯品番-->
          <template v-slot:[`item.accessoryProduct`]="{ item }">
            <v-row :class="item.setClass">
              <v-col v-if="item.accessoryProduct" style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="width: 180px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.accessoryProduct }}
                    </div>
                  </template>
                  <div>{{ item.accessoryProduct }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- ロット-->
          <template v-slot:[`item.lotNo`]="{ item }">
            <v-row :class="item.setClass" style="text-align: left !important">
              <v-col
                v-if="item.lotNo.length > 15 || item.dueDate.length > 15"
                style="padding: 0 12px"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="width: 350px" v-bind="attrs" v-on="on">
                      {{ item.lotNo }}<br />
                      {{ item.dueDate }}
                    </div>
                  </template>
                  <div>{{ item.lotNo }}<br />{{ item.dueDate }}</div>
                </v-tooltip>
              </v-col>
              <v-col v-else style="padding: 0 12px">
                {{ item.lotNo }}<br />
                {{ item.dueDate }}
              </v-col>
            </v-row>
          </template>
          <!-- ロケ -->
          <template v-slot:[`item.locationNo`]="{ item }">
            <v-row :class="item.setClass">
              <v-col v-if="item.locationNo" style="padding: 0 12px">
                <div style="text-align: left !important">{{ item.locationNo }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 数量(入)-->
          <template v-slot:[`item.inStock`]="{ item }">
            <v-row :class="item.setClass">
              <v-col style="padding: 0 12px">
                <div style="text-align: right !important">{{ item.inStock }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 入荷伝票No -->
          <template v-slot:[`item.inListNo`]="{ item }">
            <v-row :class="item.setClass">
              <v-col style="padding: 0 12px">
                <div class="listNo-style">{{ item.inListNo }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 入荷元 -->
          <template v-slot:[`item.inFromName`]="{ item }">
            <v-row :class="item.setClass" style="text-align: left !important; padding: 0 12px">
              <v-col v-if="item.inFromName" style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="width: 180px" v-bind="attrs" v-on="on">
                      {{ item.inFromCd }}
                    </div>
                  </template>
                  <div>{{ item.inFromCd }}</div>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="width: 180px" v-bind="attrs" v-on="on">
                      {{ item.inFromName }}
                    </div>
                  </template>
                  <div>{{ item.inFromName }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- 出荷伝票No-->
          <template v-slot:[`item.outListNo`]="{ item }">
            <v-row :class="item.setClass">
              <v-col style="padding: 0 12px">
                <div class="listNo-style">{{ item.outListNo }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 出荷先名 -->
          <template v-slot:[`item.outToName`]="{ item }">
            <v-row :class="item.setClass">
              <v-col v-if="item.outToName" style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="width: 180px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.outToName }}
                    </div>
                  </template>
                  <div>{{ item.outToName }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- 納入先 -->
          <template v-slot:[`item.deliveryDestination`]="{ item }">
            <v-row :class="item.setClass">
              <v-col v-if="item.deliveryDestination" style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="width: 180px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.deliveryDestination }}<br />
                      {{ item.deliveryDestinationNm }}
                    </div>
                  </template>
                  <div>{{ item.deliveryDestination }}</div>
                  <div>{{ item.deliveryDestinationNm }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- ユーザー品番 -->
          <template v-slot:[`item.userProductCd`]="{ item }">
            <v-row :class="item.setClass">
              <v-col v-if="item.userProductCd" style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="width: 200px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.userProductCd }}<br />
                      {{ item.userProductNm }}
                    </div>
                  </template>
                  <div>{{ item.userProductCd }}</div>
                  <div>{{ item.userProductNm }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- 付帯品番 -->
          <template v-slot:[`item.userAccessoryProduct`]="{ item }">
            <v-row :class="item.setClass">
              <v-col v-if="item.userAccessoryProduct" style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="width: 180px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.userAccessoryProduct }}
                    </div>
                  </template>
                  <div>{{ item.userAccessoryProduct }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- ロット(ユーザー) -->
          <template v-slot:[`item.userLotNo`]="{ item }">
            <v-row :class="item.setClass">
              <v-col v-if="item.userLotNo" style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="width: 120px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.userLotNo }}
                    </div>
                  </template>
                  <div>{{ item.userLotNo }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- 搬入日 -->
          <template v-slot:[`item.deliveryDate`]="{ item }">
            <v-row :class="item.setClass">
              <v-col style="padding: 0 12px">
                <div>{{ item.deliveryDate }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 搬入時刻 -->
          <template v-slot:[`item.deliveryTime`]="{ item }">
            <v-row :class="item.setClass">
              <v-col>
                <div>{{ item.deliveryTime }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 搬入場所 -->
          <template v-slot:[`item.deliveryDestinationy`]="{ item }">
            <v-row :class="item.setClass">
              <v-col v-if="item.deliveryDestinationy" style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="width: 200px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.deliveryDestinationy }}
                    </div>
                  </template>
                  <div>{{ item.deliveryDestinationy }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- 担当者名 -->
          <template v-slot:[`item.userName`]="{ item }">
            <v-row v-if="item.userName" :class="item.setClass">
              <v-col style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="width: 170px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.userName }}
                    </div>
                  </template>
                  <div>{{ item.userName }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- 有償/無償-->
          <template v-slot:[`item.freeCostDivName`]="{ item }">
            <v-row :class="item.setClass">
              <v-col style="padding: 0 12px">
                <div style="text-align: left !important">{{ item.freeCostDivName }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 品質区分 -->
          <template v-slot:[`item.qualityDivName`]="{ item }">
            <v-row :class="item.setClass">
              <v-col style="padding: 0 12px">
                <div style="text-align: left !important">{{ item.qualityDivName }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 責任区分 -->
          <template v-slot:[`item.blameDivName`]="{ item }">
            <v-row :class="item.setClass">
              <v-col style="padding: 0 12px">
                <div style="text-align: left !important">{{ item.blameDivName }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 管理No. -->
          <template v-slot:[`item.itemManageNo`]="{ item }">
            <v-row :class="item.setClass">
              <v-col v-if="item.itemManageNo" style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="width: 220px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.itemManageNo }}
                    </div>
                  </template>
                  <div>{{ item.itemManageNo }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- 管理No.org -->
          <template v-slot:[`item.itemManageNoOrg`]="{ item }">
            <v-row :class="item.setClass">
              <v-col v-if="item.itemManageNo" style="padding: 0 12px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="width: 220px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.itemManageNoOrg }}
                    </div>
                  </template>
                  <div>{{ item.itemManageNoOrg }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
export default {
  name: appConfig.SCREEN_ID.P_MNG_011,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // メニュー
    openMenu: null,

    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: true,
    },
    // ヘッダ
    headerItems: [
      // 日付
      {
        text: i18n.tc("label.lbl_date"),
        value: "opeHistDate",
        width: "202px",
        align: "center",
        class: "date-fixed",
      },
      // 画面名
      {
        text: i18n.tc("label.lbl_screenName"),
        value: "opeDispName",
        width: "220px",
        align: "center",
        class: "screen-name-fixed",
      },
      // 工程名
      {
        text: i18n.tc("label.lbl_opeHist"),
        value: "procName",
        width: "80px",
        align: "center",
        class: "ope-hist-fixed",
      },
      // 品番
      {
        text: i18n.tc("label.lbl_productCode"),
        value: "itemCd",
        width: "200px",
        align: "center",
        class: "product-code-fixed",
      },
      //付帯品番
      {
        text: i18n.tc("label.lbl_accessoryProduct"),
        value: "accessoryProduct",
        width: "200px",
        align: "center",
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lotNo",
        width: "350px",
        align: "left",
      },
      // ロケ
      {
        text: i18n.tc("label.lbl_location"),
        value: "locationNo",
        width: "122px",
        align: "center",
      },
      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "inStock",
        width: "140px",
        align: "center",
      },
      // 入荷伝票No
      {
        text: i18n.tc("label.lbl_arrivalSlipNo"),
        value: "inListNo",
        width: "200px",
        align: "center",
      },
      // 入荷元
      {
        text: i18n.tc("label.lbl_arrival"),
        value: "inFromName",
        width: "180px",
        align: "center",
      },
      // 出荷伝票No
      {
        text: i18n.tc("label.lbl_shipSlipNo"),
        value: "outListNo",
        width: "200px",
        align: "center",
      },
      // 出荷先
      {
        text: i18n.tc("label.lbl_shippingTo"),
        value: "outToName",
        width: "180px",
        align: "center",
      },
      //ユーザー品番/品名
      {
        text: "ユ" + i18n.tc("label.lbl_productCode"),
        value: "userProductCd",
        width: "200px",
        align: "center",
      },
      //付帯品番(ユーザー)
      {
        text: i18n.tc("label.lbl_accessoryProduct"),
        value: "userAccessoryProduct",
        width: "200px",
        align: "center",
      },
      //ロット(ユーザー)
      {
        text: i18n.tc("label.lbl_lot"),
        value: "userLotNo",
        width: "120px",
        align: "center",
      },
      //納入先
      {
        text: i18n.tc("label.lbl_deliveryDestination"),
        value: "deliveryDestination",
        width: "180px",
        align: "center",
      },
      //搬入日
      {
        text: i18n.tc("label.lbl_deliveryDate"),
        value: "deliveryDate",
        width: "150px",
        align: "center",
      },
      //搬入時刻
      {
        text: i18n.tc("label.lbl_deliveryTime"),
        value: "deliveryTime",
        width: "120px",
        align: "center",
      },
      //搬入場所
      {
        text: i18n.tc("label.lbl_deliveryDestinationy"),
        value: "deliveryDestinationy",
        width: "200px",
        align: "center",
      },
      // 担当者名
      {
        text: i18n.tc("label.lbl_popDestinationNm"),
        value: "userName",
        width: "170px",
        align: "center",
      },
      // 有償無償区分名
      {
        text: i18n.tc("label.lbl_isPaid"),
        value: "freeCostDivName",
        width: "112px",
        align: "center",
      },
      // 品質区分名
      {
        text: i18n.tc("label.lbl_arrivalStatus"),
        value: "qualityDivName",
        width: "105px",
        align: "center",
      },
      // 責任区分名
      {
        text: i18n.tc("label.lbl_blameDiv"),
        value: "blameDivName",
        width: "105px",
        align: "center",
      },
      // 管理No
      {
        text: i18n.tc("label.lbl_managementNo"),
        value: "itemManageNo",
        width: "220px",
        align: "center",
      },
      // 管理NoOrg
      {
        text: i18n.tc("label.lbl_managementNo") + "_org",
        value: "itemManageNoOrg",
        width: "220px",
        align: "center",
      },
    ],
    inputList: [],
  }),
  methods: {
    openPopup() {
      this.pListPopup = true;
    },
    // 初期化
    init() {
      //展開情報検索
      const config = this.$httpClient.createRequestBodyConfig();
      // 共通IF項目 画面ID
      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MNG_011;

      // ローディング画面表示ON
      this.loadingCounter = 1;

      config.reqIdv.deploymentDiv = this.$route.params.deploymentDiv;

      //管理番号の配列をセット
      config.reqIdv.itemManageNoList = this.$route.params.checkList.slice();

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_TRACE_DEPLOYMENT, config, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              var number = "0";
              var setClass = "";

              jsonData.resIdv.traceDeployment.forEach((row) => {
                if (row.number != number) {
                  number = row.number;
                  setClass = "itemManageSubNo-one";
                } else {
                  setClass = "";
                }
                let lotNoObj = commonUtil.convertLotNo(row.lotNo);
                list.push({
                  //管理番号
                  itemManageNo: row.itemManageNo,
                  //1行目色付け
                  setClass: setClass,
                  //管理番号.org
                  itemManageNoOrg: row.itemManageNoOrg,
                  //工程実績日付
                  opeHistDate: dateTimeHelper.convertUTC2JST(row.processDate).substr(0, 19),
                  //操作画面名
                  opeDispName: row.opeDispName,
                  //工程名
                  procName: row.procCdName,
                  //品番
                  itemCd: row.itemCd.substring(0, 50).trimRight(),
                  //付帯品番
                  accessoryProduct: row.itemCd.slice(-50).trimRight(),
                  //品名
                  itemName: row.itemName,
                  //ロット + S/N
                  lotNo: lotNoObj.lot,
                  //期限日 + 付属キー１ + 付属キー２
                  dueDate: lotNoObj.dueDate,
                  //ロケーション
                  locationNo: row.locationNo ?? "",
                  //数量
                  inStock: commonUtil.formatToCurrency(Number(row.stock)),
                  //入荷伝票No.
                  inListNo: row.inListNo,
                  //入荷元
                  inFromCd: row.fromCd,
                  inFromName: row.fromSidName,
                  //出荷伝票No
                  outListNo: row.outListNo,
                  //出荷先
                  outToCd: row.toCd,
                  outToName: row.toSidName,
                  //納入先
                  deliveryDestination: row.deliveryCd,
                  deliveryDestinationNm: row.deliveryName,
                  //ユーザー品番
                  userProductCd: row.userItemCd.trimRight(),
                  //ユーザー品名
                  userProductNm: row.userItemName,
                  //搬入日
                  deliveryDate:
                    row.deliveryDate != null && row.deliveryDate != ""
                      ? row.deliveryDate.substr(0, 4) +
                        "/" +
                        row.deliveryDate.substr(4, 2) +
                        "/" +
                        row.deliveryDate.substr(6, 2)
                      : "",
                  //搬入時刻
                  deliveryTime:
                    row.deliveryTime != null && row.deliveryTime != ""
                      ? row.deliveryTime.substr(0, 2) + ":" + row.deliveryTime.substr(2, 2)
                      : "",
                  //搬入場所
                  deliveryDestinationy: row.deliveryPlaceCd,
                  //担当者
                  userName: row.userName,
                  //有償/無償
                  freeCostDivName: row.freeCostDivName,
                  //品質区分
                  qualityDivName: row.qualityDivName,
                  //責任区分
                  blameDivName: row.blameDivName,
                });
              });
              this.inputList = list;
              resolve(response);

              // 0件の場合は、ボタンを非活性にする
              if (this.inputList.length == 0) {
                this.disableBtn = true;
              } else {
                this.disableBtn = false;
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 戻るボタン押下
    backPage() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MNG_010,
        params: {
          checkList: this.checkedList,
          // 検索条件を渡す
          deploymentDiv: this.$route.params.deploymentDiv, //指示選択
          listNo: this.$route.params.listNo, //伝票No
          actualDateFrom: this.$route.params.actualDateFrom, //日付From
          actualDateTo: this.$route.params.actualDateTo, //日付To
          clientSid: this.$route.params.clientSid, //取引先(メーカー)
          itemStandardSid: this.$route.params.itemStandardSid, //メーカー品番/品名
          itemListsession: this.$route.params.itemListsession, //メーカー品番リスト
          lotNo: this.$route.params.lotNo, //ロット(メーカー)
          serial: this.$route.params.serial, //S/N(メーカー)
          dueDate: this.$route.params.dueDate, //期限日
          lotSubkey1: this.$route.params.lotSubkey1, //付属キー１
          lotSubkey2: this.$route.params.lotSubkey2, //付属キー２
          userClientSid: this.$route.params.userClientSid, //取引先(ユーザー)
          userItemStandardSid: this.$route.params.userItemStandardSid, //ユーザー品番/品名
          userItemListsession: this.$route.params.userItemListsession, //ユーザー品番リスト
          userLotNo: this.$route.params.userLotNo, //ロット(ユーザー)
          userSerial: this.$route.params.userSerial, //S/N(ユーザー)
          location: this.$route.params.location, //ロケーション
          manager: this.$route.params.manager, //担当者
          place: this.$route.params.place, //場所
          managementNo: this.$route.params.managementNo, //管理No
          placeName: this.$route.params.placeName, //場所名
          name: appConfig.SCREEN_ID.P_MNG_011,
        },
      });
    },
  },
  created() {
    this.init();
  },
  computed: {},
  watch: {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.overflow {
  overflow: hidden;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr,
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr td {
  background-color: #ffffff;
}

#listData.v-data-table--fixed-header
  ::v-deep
  .v-data-table__wrapper
  tbody
  tr:has(> td > div.itemManageSubNo-one),
#listData.v-data-table--fixed-header
  ::v-deep
  .v-data-table__wrapper
  tbody
  tr
  td:has(> div.itemManageSubNo-one) {
  background-color: #dde5f0;
}

//table固定化処理
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .date-fixed {
  position: sticky !important;
  left: 0px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .screen-name-fixed {
  position: sticky !important;
  left: 202px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .ope-hist-fixed {
  position: sticky !important;
  left: 454px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-code-fixed {
  position: sticky !important;
  left: 534px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(2) {
  position: sticky !important;
  left: 202px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(3) {
  position: sticky !important;
  left: 454px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(4) {
  position: sticky !important;
  left: 534px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper thead tr {
  background: #effad9;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper {
  max-height: 775px;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr {
  height: 60px;
}
</style>
