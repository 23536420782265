<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_entry/exitHistory" />

      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <!-- v-row 1 取引先 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :hint="setSupplier()"
                :rules="[rules.supplierRequired]"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- v-row 1 取引先 終わり -->

            <!-- v-row 1 出荷日 カレンダー 始まり -->
            <!-- (FROM) 始まり -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                id="cal"
                v-model="shippingScheduleDateFrom"
                :label="$t('label.lbl_entry/exitDate') + '（From）'"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <!-- <span class="require asterisk-spacer">*</span> -->

            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="shippingScheduleDateFromCal" @input="dateMenu = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="preDateOfScheduleFrom"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDateOfScheduleFrom"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- (FROM) 終わり -->

            <!-- カレンダーの間 -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- (TO) 始まり -->
            <div class="date-style">
              <!-- カレンダーテキストボックス部分 -->
              <v-text-field
                outlined
                dense
                v-model="shippingScheduleDateTo"
                class="txt-single date-style"
                :label="$t('label.lbl_entry/exitDate') + '（To）'"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>

            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuTo"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="shippingScheduleDateToCal" @input="dateMenuTo = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="preDateOfScheduleTo"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDateOfScheduleTo"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- (TO) 終わり -->
            <!-- v-row 1 出荷予定日 カレンダー 終わり -->

            <!-- v-row 1 品番/品名 始まり -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                id="get-productCode"
                v-model="productCodeSelected"
                :items="productCodeList"
                @change="(event) => changeProductNm(event)"
                :hint="setProductCode()"
                :search-input.sync="search"
                :rules="[rules.productRequired]"
                :label="$t('label.lbl_productCnCd')"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <!-- 伝票No -->
                <template v-slot:[`item.billingSlipNo`]="{ item }">
                  <div class="listNo-style">{{ item.billingSlipNo }}</div>
                </template>
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- v-row 1 品番 終わり -->

            <!-- v-row 1 品質区分 始まり -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="qualitySelected"
                :items="qualityList"
                :label="$t('label.lbl_arrivalStatus')"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- v-row 1 品質区分 終わり -->

            <!-- v-row 1 表示区分 始まり -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="displaySelected"
                :items="displayList"
                :rules="[rules.displayRequired]"
                :label="$t('label.lbl_displaydiv')"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- v-row 1 表示区分 終わり -->

            <!-- v-row 1 入/出 始まり -->
            <!-- <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="qualitySelected"
                :items="qualityList"
                :label="$t('label.lbl_entry/exit')"
                class="txt-single"
                persistent-hint
              > -->
            <!-- アイテム一覧の表示 -->
            <!-- <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template> -->
            <!-- * -->
            <!-- </v-autocomplete>
            </div> -->
            <!-- v-row 1 入/出 終わり -->
          </v-row>

          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn class="get-btn" @click="getList()">{{ $t("btn.btn_search") }}</v-btn>
              </div>

              <div class="btn-area">
                <!--CSV出力ボタン-->
                <v-btn
                  color="primary"
                  id="btn-search"
                  class="post-btn"
                  @click="getInvoiceIssue()"
                  >{{ $t("btn.btn_outputcsv") }}</v-btn
                >
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <!-- <span>総件数：{{ totalCount }}件</span> -->
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          :item-class="rowClass"
          id="listDataSpecial"
          class="tableData"
          :class="classHeightSet"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          disable-sort
          disable-pagination
          :hide-default-footer="true"
          height="550px"
          group-by="wareInOutDate"
          ref="ref"
        >
          <template v-slot:item="{ item }">
            <tr v-show="item.isHeader == false">
              <td></td>
              <!-- 入出庫日 -->
              <td>
                <div v-show="false">{{ item.entExtDate }}</div>
              </td>
              <!-- 入/出 -->
              <td>
                <div style="max-width: 270px">{{ item.wareInOut }}</div>
              </td>
              <!-- <td>
                <div v-if="item.wareInOut.length > 5">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="txt-wareInOut" style="max-width: 60px" v-bind="attrs" v-on="on">
                        {{ item.wareInOut }}
                      </div>
                    </template>
                    <span>{{ item.wareInOut }}</span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <div>{{ item.wareInOut }}</div>
                </div>
              </td> -->
              <!-- 繰越 -->
              <td>
                <div v-show="false">{{ item.carryOver }}</div>
              </td>
              <!-- 入庫 -->
              <td>
                <div>{{ item.entering }}</div>
              </td>
              <!-- 出庫 -->
              <td>
                <div v-if="item.exitingckeck >= 0">{{ item.exiting }}</div>
                <div v-else class="negative-inventory">{{ item.exiting }}</div>
              </td>
              <!-- 残数 -->
              <td>
                <div v-show="false">{{ item.remainingQty }}</div>
              </td>
              <!-- 伝票No -->
              <td>
                <div>{{ item.billingSlipNo }}</div>
              </td>
              <!-- <td>
                <div v-if="item.billingSlipNo.length > 5">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="max-width: 60px" v-bind="attrs" v-on="on">
                        {{ item.billingSlipNo }}
                      </div>
                    </template>
                    <span>{{ item.billingSlipNo }} </span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <div>{{ item.billingSlipNo }}</div>
                </div>
              </td> -->
              <!-- 倉庫 -->
              <!-- <td>
                <div style="max-width: 115px">{{ item.warehouse }}</div>
              </td> -->
              <td>
                <div v-if="item.warehouse && item.warehouse.length > 7">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="txt-warehouse" style="max-width: 150px" v-bind="attrs" v-on="on">
                        {{ item.warehouse }}
                      </div>
                    </template>
                    <span>{{ item.warehouse }} </span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <div>{{ item.warehouse }}</div>
                </div>
              </td>
              <!-- ロット -->
              <!-- <td>
                <div style="max-width: 70px">{{ item.lot }}</div>
              </td> -->
              <td>
                <div v-if="item.lot && item.lot.length > 10">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="max-width: 180px" v-bind="attrs" v-on="on">
                        {{ item.lot }}
                      </div>
                    </template>
                    <span>{{ item.lot }} </span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <div>{{ item.lot }}</div>
                </div>
              </td>
              <!-- S/N-->
              <td>
                <div v-if="item.serial && item.serial.length > 10">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="max-width: 180px" v-bind="attrs" v-on="on">
                        {{ item.serial }}
                      </div>
                    </template>
                    <span>{{ item.serial }} </span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <div>{{ item.serial }}</div>
                </div>
              </td>
              <!-- 期限日 -->
              <td>
                <div>{{ item.dueDate }}</div>
              </td>
              <!-- 付属キー１ -->
              <td>
                <div v-if="item.lotSubkey1 && item.lotSubkey1.length > 10">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="max-width: 180px" v-bind="attrs" v-on="on">
                        {{ item.lotSubkey1 }}
                      </div>
                    </template>
                    <span>{{ item.lotSubkey1 }} </span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <div>{{ item.lotSubkey1 }}</div>
                </div>
              </td>
              <!-- 付属キー２ -->
              <td>
                <div v-if="item.lotSubkey2 && item.lotSubkey2.length > 10">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="max-width: 180px" v-bind="attrs" v-on="on">
                        {{ item.lotSubkey2 }}
                      </div>
                    </template>
                    <span>{{ item.lotSubkey2 }} </span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <div>{{ item.lotSubkey2 }}</div>
                </div>
              </td>
              <!-- ステータス -->
              <td>
                <div>{{ item.status }}</div>
              </td>
              <!-- ロケ―ションNo -->
              <td>
                <div style="max-width: 122px">{{ item.location }}</div>
              </td>
              <!-- <td>
                <div v-if="item.location.length > 20">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-location" style="max-width: 65px" v-bind="attrs" v-on="on">
                        {{ item.location }}
                      </div>
                    </template>
                    <span>{{ item.location }} </span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <div>{{ item.location }}</div>
                </div>
              </td> -->
              <!-- 出荷先 -->
              <!-- <td>
                <div style="max-width: 100px">{{ item.destination }}</div>
              </td> -->
              <td>
                <div v-if="item.destination && item.destination.length > 7">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="max-width: 150px" v-bind="attrs" v-on="on">
                        {{ item.destination }}
                      </div>
                    </template>
                    <span>{{ item.destination }} </span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <div>{{ item.destination }}</div>
                </div>
              </td>
              <!-- 作業日 -->
              <td>
                <div class="text-inOutDate" style="max-width: 100px">{{ item.inOutDate }}</div>
              </td>
              <!-- 作業者 -->
              <td>
                <div v-if="item.managementNo.length > 4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="max-width: 60px" v-bind="attrs" v-on="on">
                        {{ item.worker }}
                      </div>
                    </template>
                    <span>{{ item.worker }} </span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <div>{{ item.managementNo }}</div>
                </div>
              </td>
              <!--管理No -->
              <td>
                <div v-if="isOpenDtl">
                  <v-btn color="blue darken-1" text @click="openUpdateDelete(item)">
                    <span class="strageQty">{{ item.managementNo }}</span>
                  </v-btn>
                </div>
                <div v-else>{{ item.managementNo }}</div>
              </td>
            </tr>
          </template>

          <!-- プルダウン -->
          <template v-slot:[`group.header`]="{ group, items, isOpen, toggle }">
            <td class="header-mdi-plus" colspan="1">
              <v-container fluid>
                <v-btn @click="toggle" x-medium icon :ref="group">
                  <v-icon v-if="!isOpen" @click="toggleOpen(group, items)">mdi-plus</v-icon>
                  <v-icon v-else @click="toggleClose">mdi-minus</v-icon>
                </v-btn>
              </v-container>
            </td>
            <td colspan="1">{{ items[0].entExtDate }}</td>
            <td colspan="1">{{ items[0].wareInOut }}</td>
            <td class="header-position" colspan="1">{{ items[0].carryOver }}</td>
            <td class="header-position" colspan="1">{{ items[0].entering }}</td>
            <td class="header-position" colspan="1">{{ items[0].exiting }}</td>
            <td class="header-position" colspan="1">{{ items[0].remainingQty }}</td>
            <td colspan="1"></td>
            <td id="header-warehouse" colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
          </template>
        </v-data-table>
      </v-container>

      <v-container fluid>
        <v-data-table
          id="listSumDataSpecial"
          class="tableData"
          :class="classHeightSet"
          fixed-header
          :items="inputSumList"
          :headers="headerSumItems"
          disable-filtering
          disable-sort
          disable-pagination
          :hide-default-footer="true"
          height="100px"
        >
          <tr>
            <td></td>
            <td colspan="1"></td>
            <td colspan="1">{{ $t("label.lbl_totalSum") }}</td>
            <td colspan="1">{{ totalCarryOver }}</td>
            <td colspan="1">{{ totalenteringSum }}</td>
            <td colspan="1">{{ totalexitingSum }}</td>
            <td colspan="1">{{ totalremainingQty }}</td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
            <td colspan="1"></td>
          </tr>
        </v-data-table>
      </v-container>

      <!-- <v-container fluid>
        <div class="total">
          <span>合計</span>
          <span class="totalSum">{{ totalCarryOver }}</span>
          <span class="totalSum">{{ totalenteringSum }}</span>
          <span class="totalSum">{{ totalexitingSum }}</span>
          <span class="totalSum">{{ totalremainingQty }}</span>
        </div>
      </v-container> -->

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <!-- 詳細画面ポップアップ -->
      <v-dialog v-model="dialogDetails" :max-width="2000">
        <ManageOpeHistUpdate
          :isOpenDetailDialog.sync="dialogDetails"
          :entity="detailDialogData"
          class="overflow"
          v-on:closePopup="closePopup"
          @click="closePopup"
        />
      </v-dialog>
      <!-- 進捗情報詳細モーダル-->
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ManageOpeHistUpdate from "./ManageOpeHistUpdate.vue";
export default {
  name: "CompareScheduleAchievements",
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ManageOpeHistUpdate,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // 詳細ダイアログ
    dialogDetails: false,
    detailDialogData: {
      clientSid: "",
      clientName: "",
      itemManageNo: "",
    },
    isOpenDtl: sessionStorage.getItem(appConfig.SCREEN_ID.P_MNG_009) == "true" ? true : false,
    isActive: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 全選択チェックボックス
    isAllSelected: false,
    // グループ選択
    isGroupSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    u_10: false,
    singleSelect: false,
    selected: [],
    search: "",
    // 総件数
    totalCount: 0,
    // 取引先
    supplierList: [],
    suppliersSelected: "",
    // 出荷日from
    shippingScheduleDateFrom: "",
    shippingScheduleDateFromCal: "",
    // 出荷日to
    shippingScheduleDateTo: "",
    shippingScheduleDateToCal: "",
    expireDateCal: "",
    // 品番
    productCodeSelected: "",
    productCodeList: [],
    // 品質区分
    qualitySelected: "",
    qualityList: [],
    qualityDiv: "",
    // 表示区分
    displaySelected: "",
    displayList: [],
    displayInital: [],
    displayDiv: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 合計値
    totalCarryOver: 0,
    totalCarryOverSum: "",
    totalenteringSum: 0,
    totalexitingSum: 0,
    totalremainingQty: 0,

    inListSid: "", // 修正削除画面に渡すid
    // 取引先名+取引先コード
    suppliersNm: "",
    // 入荷先
    arrivalSelected: "",
    // メニュー
    openMenu: null,
    // 取引先SID
    clientSid: "",
    // 取引先名
    clientName: "",
    // 入荷予定日付キャレンダー
    dateMenu: false,
    dateMenuTo: false,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    alertMessage: "",
    // ヘッダ
    headerItems: [
      // これがないとヘッダーにロケーショングループという文言が表示されない
      {
        text: i18n.tc(""),
        value: "pulldown",
        width: "95px",
        align: "center",
        sortable: false,
        padding: "0px",
      },
      // 入出庫日
      {
        text: i18n.tc(""),
        value: "wareInOutDate",
        width: "115px",
        align: "center",
        sortable: false,
        padding: "0px",
      },
      // 入出庫日
      {
        text: i18n.tc("label.lbl_entry/exitDate"),
        value: "entExtDate",
        width: "128px",
        align: "left",
        sortable: false,
      },
      // 入/出
      {
        text: i18n.tc("label.lbl_entry/exit"),
        value: "wareInOut",
        width: "270px",
        align: "left",
        sortable: false,
      },
      // 繰越
      {
        text: i18n.tc("label.lbl_carryOver"),
        value: "carryOver",
        width: "130px",
        align: "right",
        sortable: false,
      },
      // 入庫
      {
        text: i18n.tc("label.lbl_entering"),
        value: "entering",
        width: "130px",
        align: "right",
        sortable: false,
      },
      // 出庫
      {
        text: i18n.tc("label.lbl_exiting"),
        value: "exiting",
        width: "130px",
        align: "right",
        sortable: false,
      },
      // 残数
      {
        text: i18n.tc("label.lbl_remainingQty"),
        value: "remainingQty",
        width: "130px",
        align: "right",
        sortable: false,
      },
      // 伝票No
      {
        text: i18n.tc("label.lbl_billingSlipNo"),
        value: "billingSlipNo",
        width: "240px",
        align: "left",
        sortable: false,
      },
      // 倉庫
      {
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "190px",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot"),
        value: "lot",
        width: "220px",
        align: "left",
        sortable: false,
      },
      // S/N
      {
        text: i18n.tc("label.lbl_serial"),
        value: "serial",
        width: "220px",
        align: "left",
        sortable: false,
      },
      // 期限日
      {
        text: i18n.tc("label.lbl_dueDate"),
        value: "dueDate",
        width: "220px",
        align: "left",
        sortable: false,
      },
      // 付属キー１
      {
        text: i18n.tc("label.lbl_lotSubkey1"),
        value: "lotSubkey1",
        width: "220px",
        align: "left",
        sortable: false,
      },
      // 付属キー２
      {
        text: i18n.tc("label.lbl_lotSubkey2"),
        value: "lotSubkey2",
        width: "220px",
        align: "left",
        sortable: false,
      },
      // ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "100px",
        align: "left",
        sortable: false,
      },
      // ロケNo
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "122px",
        align: "left",
        sortable: false,
      },
      // 入荷元/出荷先
      {
        text: i18n.tc("label.lbl_recieveShipTo"),
        value: "destination",
        width: "190px",
        align: "left",
        sortable: false,
      },
      // 作業日
      {
        text: i18n.tc("label.lbl_workingDay"),
        value: "inOutDate",
        width: "135px",
        align: "left",
        sortable: false,
      },
      // 作業者
      {
        text: i18n.tc("label.lbl_worker"),
        value: "worker",
        width: "92px",
        align: "left",
        sortable: false,
      },
      // 管理No
      {
        text: i18n.tc("label.lbl_managementNo"),
        value: "managementNo",
        width: "210px",
        align: "left",
        sortable: false,
      },
    ],
    // ヘッダ
    headerSumItems: [
      {
        text: i18n.tc(""),
        value: "pulldown",
        width: "95px",
        align: "center",
        sortable: false,
        padding: "0px",
      },
      // 入出庫日
      {
        text: i18n.tc("label.lbl_entry/exitDate"),
        value: "entExtDate",
        width: "128px",
        align: "left",
        sortable: false,
      },
      // 入/出
      {
        text: i18n.tc("label.lbl_entry/exit"),
        value: "wareInOut",
        width: "270px",
        align: "right",
        sortable: false,
      },
      // 繰越
      {
        text: i18n.tc("label.lbl_carryOver"),
        value: "carryOver",
        width: "130px",
        align: "right",
        sortable: false,
      },
      // 入庫
      {
        text: i18n.tc("label.lbl_entering"),
        value: "entering",
        width: "130px",
        align: "right",
        sortable: false,
      },
      // 出庫
      {
        text: i18n.tc("label.lbl_exiting"),
        value: "exiting",
        width: "130px",
        align: "right",
        sortable: false,
      },
      // 残数
      {
        text: i18n.tc("label.lbl_remainingQty"),
        value: "remainingQty",
        width: "130px",
        align: "right",
        sortable: false,
      },
      // 伝票No
      {
        text: i18n.tc("label.lbl_billingSlipNo"),
        value: "billingSlipNo",
        width: "91px",
        align: "left",
        sortable: false,
      },
      // 倉庫
      {
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "20px",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot"),
        value: "lot",
        width: "10px",
        align: "left",
        sortable: false,
      },
      // ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "10px",
        align: "left",
        sortable: false,
      },
      // ロケNo
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "10px",
        align: "left",
        sortable: false,
      },
      // 出荷先
      {
        text: i18n.tc("label.lbl_recieveShipTo"),
        value: "destination",
        width: "91px",
        align: "left",
        sortable: false,
      },
      // 作業日
      {
        text: i18n.tc("label.lbl_workingDay"),
        value: "inOutDate",
        width: "100px",
        align: "left",
        sortable: false,
      },
      // 作業者
      {
        text: i18n.tc("label.lbl_worker"),
        value: "worker",
        width: "100px",
        align: "left",
        sortable: false,
      },
      // 管理No
      {
        text: i18n.tc("label.lbl_managementNo"),
        value: "managementNo",
        width: "100px",
        align: "left",
        sortable: false,
      },
    ],
    returnURL: "",
    // 一覧ボディリスト（履歴、詳細履歴）
    inputList: [],
    // scroll用格納リスト
    inputScrollList: [],
    // 合計リスト
    inputSumList: [],
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      productRequired: (value) => !!value || i18n.tc("check.chk_inputproductCnCd"),
      displayRequired: (value) => !!value || i18n.tc("check.chk_inputDisplayDiv"),
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNumber"),
    },
  }),
  methods: {
    getTotal(items) {
      let total = 0;
      total = items.reduce((prev, current) => {
        return prev + current.value;
      }, 0);
      return total;
    },

    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.getList();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.getList();
    },

    // 初期化
    init() {
      this.suppliersSelected = "";
      this.qualitySelected = "";
      this.shippingScheduleDateFrom = dateTimeHelper.convertJST();
      this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.shippingScheduleDateTo = dateTimeHelper.convertJST();
      this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.getCustomInfo();
      this.getMstCode();
      this.getDisplayDiv();
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 出荷日付Fromを＋１日・－１日します。
     */
    preDateOfScheduleFrom() {
      if (this.shippingScheduleDateFromCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateFromCal != toDate) {
        this.shippingScheduleDateFromCal = toDate;
      } else {
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() - 1)
        );
      }
    },
    nextDateOfScheduleFrom() {
      if (this.shippingScheduleDateFromCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateFromCal != toDate) {
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 2)
        );
      } else {
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 1)
        );
      }
    },

    /**
     * 出荷日付Toを＋１日・－１日します。
     */
    preDateOfScheduleTo() {
      if (this.shippingScheduleDateToCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateToCal != toDate) {
        this.shippingScheduleDateToCal = toDate;
      } else {
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    nextDateOfScheduleTo() {
      if (this.shippingScheduleDateToCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateToCal != toDate) {
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * プルダウンチェンジイベント
     */
    changeProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.productCodeList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.search = val.text;
      }
      this.productCodeList = this.productCodeList.filter((v) => v.value == value);
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.shippingScheduleDateFromCal = null;
      }
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.shippingScheduleDateToCal = null;
      }
    },

    // 品質区分（コードマスタ)
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_QUALITY_DIV);

      Promise.all([qualityList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.qualityList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 表示区分（コードマスタ)
    getDisplayDiv() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 表示区分
      const displayList = getParameter.getCodeMst(appConfig.CODETYPE.DISPLAY_DIV);
      const displayInitalList = getParameter.getCodeMst(appConfig.CODETYPE.DISPLAY_DIV_INITIAL);

      Promise.all([displayList, displayInitalList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.displayList = result[0];
          this.displayInital = result[1];
          //表示区分初期値設定
          for (var i = 0; i < this.displayInital.length; i++) {
            if (this.displayInital[i].value == "01") {
              this.displaySelected = this.displayInital[i].text;
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * URL発行
     */
    link() {
      location.href = this.returnURL;
    },

    // CSV出力
    getInvoiceIssue() {
      if (
        // FromToの日付、入力チェック
        new Date(this.shippingScheduleDateFrom).getTime() >
        new Date(this.shippingScheduleDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else {
        if (this.$refs.form.validate()) {
          // バリデーションエラーメッセージを非表示
          this.alertMessage = "";

          // ローディング画面表示ON
          this.loadingCounter = 1;

          const body = this.$httpClient.createRequestBodyConfig();
          // 共通IF項目 画面ID
          body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MNG_006;

          // 取引先Sid
          body.reqIdv.clientSid = this.suppliersSelected;
          // 入出庫日From
          body.reqIdv.wareInOutDateFrom = dateTimeHelper.convertUTC(this.shippingScheduleDateFrom);
          // 入出庫日To
          var arr = this.shippingScheduleDateTo + " 23:59:59.999";
          body.reqIdv.wareInOutDateTo = dateTimeHelper.convertUTC(arr);
          // タイムゾーン
          body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
          // 商品Sid
          body.reqIdv.itemStandardSid = this.productCodeSelected;
          // 品質区分
          if (this.qualitySelected) {
            body.reqIdv.qualityDiv = this.qualitySelected;
          }
          // 表示区分
          //body.reqIdv.displayDiv = this.displayDiv;
          if (this.displaySelected == "01") {
            body.reqIdv.displayDiv = "01";
          } else if (this.displaySelected == "02") {
            body.reqIdv.displayDiv = "02";
          } else if (this.displaySelected == "03") {
            body.reqIdv.displayDiv = "03";
          }
          // console.debug("getInvoiceIssue() Body", body);
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .securePost(appConfig.API_URL.BIZ_ENTEXTHISTDATA, body, appConfig.APP_CONFIG)
              .then((response) => {
                // console.debug("getInvoiceIssue() Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // 成功
                if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                  // 返却されたurlをデータに格納、ダウンロードボタンの活性化
                  this.returnURL = jsonData.resIdv.filePath;
                  this.link();
                  resolve(response);
                } else {
                  // エラー時
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      }
    },

    rowClass(item) {
      let C = "row-header";
      if (item.isHeader) {
        C = "row-header";
      }
      return C;
    },

    //検索ボタン押下時
    getList() {
      this.inputList.length = 0;

      if (this.$refs.form.validate()) {
        // バリデーションエラーメッセージを非表示
        this.alertMessage = "";
        let self = this;
        this.alertMessage = "";

        // Fromの日付がtoよりも先だった場合、エラー
        if (this.suppliersSelected == "") {
          return;
        }
        if (
          new Date(this.shippingScheduleDateFrom).getTime() >
          new Date(this.shippingScheduleDateTo).getTime()
        ) {
          this.alertMessage = i18n.tc("check.chk_inputFromVali");
        } else {
          // ローディング画面表示ON
          this.loadingCounter = 1;

          // httpClientで使用する共通IFパラメータ等を作成する。
          const config = this.$httpClient.createGetApiRequestConfig();

          // 取引先Sid
          config.params.clientSid = this.suppliersSelected;
          // 入出庫日From
          config.params.wareInOutDateFrom = dateTimeHelper.convertUTC(
            this.shippingScheduleDateFrom
          );
          // 入出庫日To
          var arr = this.shippingScheduleDateTo + " 23:59:59.999";
          config.params.wareInOutDateTo = dateTimeHelper.convertUTC(arr);
          // タイムゾーン
          config.params.timeZone = dateTimeHelper.getTimeZone();
          // 品番
          for (let i = 0; i < this.productCodeList.length; i++) {
            if (this.productCodeList[i].value == this.productCodeSelected) {
              config.params.itemCd = this.productCodeList[i].code;
            }
          }
          config.params.itemStandardSid = this.productCodeSelected;
          // 品質区分
          // if (this.qualitySelected == "01") {
          //   config.params.qualityDiv = "01";
          // } else if (this.qualitySelected == "02") {
          //   config.params.qualityDiv = "02";
          // }
          if (this.qualitySelected) {
            config.params.qualityDiv = this.qualitySelected;
          }
          config.params.displayDiv = this.displayDiv;
          // 表示区分
          if (this.displaySelected == "01") {
            config.params.displayDiv = "01";
          } else if (this.displaySelected == "02") {
            config.params.displayDiv = "02";
          } else if (this.displaySelected == "03") {
            config.params.displayDiv = "03";
          }

          // console.debug("getList() Config", config);
          // 接続先のAPI_URLを入力
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.BIZ_ENTEXTHIST, config)
              .then((response) => {
                // console.debug("getList() Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                // 正常時
                if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                  const list = [];

                  jsonData.resIdv.wareInOutHist.forEach((row) => {
                    if (this.displaySelected != "03") {
                      list.push({
                        isHeader: true,
                        wareInOutDate: dateTimeHelper
                          .convertUTC2JST(row.wareInOutDate)
                          .substr(0, 10), // 入出庫日 プルダウン用
                        entExtDate: dateTimeHelper.convertUTC2JST(row.wareInOutDate).substr(0, 10), // 入出庫日
                        wareInOut: row.wareInOut, // 入/出
                        carryOver: commonUtil.formatToCurrency(Number(row.carryOver)), // 繰越
                        carryOverNum: Number(row.carryOver), // 繰越計算用
                        entering: commonUtil.formatToCurrency(Number(row.wareIn)), // 入庫
                        enteringNum: Number(row.wareIn), // 入庫計算用
                        exiting: commonUtil.formatToCurrency(Number(row.wareOut)), // 出庫
                        exitingNum: Number(row.wareOut), // 出庫計算用
                        remainingQty: commonUtil.formatToCurrency(Number(row.restCnt)), // 残数
                        billingSlipNo: "", // 伝票番号
                        warehouse: "",
                        lot: "", // ロット
                        serial: "", //S/N
                        qualityDiv: "",
                        status: "", // 品質区分
                        location: "", // ロケNo
                        destination: "", // 出荷先
                        inOutDate: "", // 作業日
                        worker: "", // 作業者
                        managementNo: "", // 管理No
                      });
                    } else if (this.displaySelected == "03") {
                      list.push({
                        isHeader: true,
                        wareInOutDate: dateTimeHelper
                          .convertUTC2JST(row.wareInOutDate)
                          .substr(0, 10), // 入出庫日 プルダウン用
                        entExtDate: dateTimeHelper.convertUTC2JST(row.wareInOutDate).substr(0, 10), // 入出庫日
                        wareInOut: row.wareInOut, // 入/出
                        carryOver: "", // 繰越
                        carryOverNum: Number(row.carryOver), // 繰越計算用
                        entering: commonUtil.formatToCurrency(Number(row.wareIn)), // 入庫
                        enteringNum: Number(row.wareIn), // 入庫計算用
                        exiting: commonUtil.formatToCurrency(Number(row.wareOut)), // 出庫
                        exitingNum: Number(row.wareOut), // 出庫計算用
                        remainingQty: "", // 残数
                        billingSlipNo: "", // 伝票番号
                        warehouse: "",
                        lot: "", // ロット
                        qualityDiv: "",
                        status: "", // 品質区分
                        location: "", // ロケNo
                        destination: "", // 出荷先
                        inOutDate: "", // 作業日
                        worker: "", // 作業者
                        managementNo: "", // 管理No
                      });
                    }
                    this.inputList = [];
                    this.inputList = list;
                    this.inputList.sort();

                    this.$nextTick(() => {
                      let table = self.$refs.ref;
                      let keys = Object.keys(table.$vnode.componentInstance.openCache);
                      // keys.pop();
                      keys.forEach((x) => {
                        table.$vnode.componentInstance.openCache[x] = false;
                      });
                    });

                    // 検索結果がある場合、class条件分岐表示
                    if (this.inputList.length < 1) {
                      this.isActive = true;
                    }

                    // const carryOverSumList = "";
                    let enteringSumList = [];
                    let exitingSumList = [];

                    this.inputScrollList = this.inputList;
                    for (let i = 0; i < this.inputList.length; i++) {
                      // 繰越合計( ＝ 検索内の一番古い日付の繰越数 )
                      this.totalCarryOver = this.inputList[0].carryOver;
                      this.totalCarryOverSum = this.inputList[0].carryOverNum;
                      enteringSumList.push(this.inputList[i].enteringNum);
                      exitingSumList.push(this.inputList[i].exitingNum);
                    }
                    // 入庫合計
                    this.totalenteringSum = enteringSumList.reduce(function (a, x) {
                      return a + x;
                    });
                    // 出庫合計
                    this.totalexitingSum = exitingSumList.reduce(function (a, x) {
                      return a + x;
                    });
                    // 残数合計
                    this.totalremainingQty =
                      Number(this.totalCarryOverSum) +
                      Number(this.totalenteringSum) -
                      Number(this.totalexitingSum);

                    this.totalCarryOver = commonUtil.formatToCurrency(this.totalCarryOver);
                    this.totalenteringSum = commonUtil.formatToCurrency(this.totalenteringSum);
                    this.totalexitingSum = commonUtil.formatToCurrency(this.totalexitingSum);
                    this.totalremainingQty = commonUtil.formatToCurrency(this.totalremainingQty);

                    let sumList = [];

                    if (this.displaySelected != "03") {
                      sumList.push({
                        isHeader: true,
                        wareInOutDate: "", // 入出庫日 プルダウン用
                        entExtDate: "", // 入出庫日
                        wareInOut: i18n.tc("label.lbl_totalSum"), // 入/出
                        carryOver: this.totalCarryOver, // 繰越
                        entering: this.totalenteringSum, // 入庫
                        exiting: this.totalexitingSum, // 出庫
                        remainingQty: this.totalremainingQty, // 残数
                        billingSlipNo: "", // 伝票番号
                        warehouse: "",
                        lot: "", // ロット
                        qualityDiv: "",
                        status: "", // 品質区分
                        location: "", // ロケNo
                        destination: "", // 出荷先
                        inOutDate: "", // 作業日
                        worker: "", // 作業者
                        managementNo: "", // 管理No
                      });
                    } else if (this.displaySelected == "03") {
                      sumList.push({
                        isHeader: true,
                        wareInOutDate: "", // 入出庫日 プルダウン用
                        entExtDate: "", // 入出庫日
                        wareInOut: i18n.tc("label.lbl_totalSum"), // 入/出
                        carryOver: "", // 繰越
                        entering: this.totalenteringSum, // 入庫
                        exiting: this.totalexitingSum, // 出庫
                        remainingQty: "", // 残数
                        billingSlipNo: "", // 伝票番号
                        warehouse: "",
                        lot: "", // ロット
                        qualityDiv: "",
                        status: "", // 品質区分
                        location: "", // ロケNo
                        destination: "", // 出荷先
                        inOutDate: "", // 作業日
                        worker: "", // 作業者
                        managementNo: "", // 管理No
                      });
                    }
                    this.inputSumList = [];
                    this.inputSumList = sumList;
                  });

                  if (this.inputList.length == 0) {
                    for (let i = 0; i < this.inputSumList.length; i++) {
                      this.inputSumList[i].carryOver = 0;
                      this.inputSumList[i].entering = 0;
                      this.inputSumList[i].exiting = 0;
                      this.inputSumList[i].remainingQty = 0;
                    }
                  }

                  // エラー時
                } else {
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                }
                resolve(response);
                // データが無い場合、テーブルデータを空にする。
                if (this.inputList.length == 0) {
                  this.inputList = [];
                }
              })
              .catch((resolve) => {
                console.error("getList() Resolve", resolve);
                this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject(resolve);
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      }
    },

    toggleOpen(group, items) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.wareInOutDateFrom = dateTimeHelper.convertUTC(group);

      var arr = group + " 23:59:59.999";
      config.params.wareInOutDateTo = dateTimeHelper.convertUTC(arr);
      // タイムゾーン
      config.params.timeZone = dateTimeHelper.getTimeZone();

      // 品番
      for (let i = 0; i < this.productCodeList.length; i++) {
        if (this.productCodeList[i].value == this.productCodeSelected) {
          config.params.itemCd = this.productCodeList[i].code;
        }
      }
      config.params.itemStandardSid = this.productCodeSelected;
      config.params.clientSid = this.suppliersSelected;

      // 品質区分
      // if (this.qualitySelected == "01") {
      //   config.params.qualityDiv = "01";
      // } else if (this.qualitySelected == "02") {
      //   config.params.qualityDiv = "02";
      // }
      if (this.qualitySelected) {
        config.params.qualityDiv = this.qualitySelected;
      }
      // 表示区分
      if (this.displaySelected == "01") {
        config.params.displayDiv = "01";
      } else if (this.displaySelected == "02") {
        config.params.displayDiv = "02";
      } else if (this.displaySelected == "03") {
        config.params.displayDiv = "03";
      }

      // console.debug("toggleOpen(group, items) Config", config);
      // 接続先のAPI_URLを入力
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_ENTEXTDETAILHIST, config)
          .then((response) => {
            // console.debug("toggleOpen(group, items) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            var newArray = this.inputList.filter(function (item) {
              return group != item.wareInOutDate || item.isHeader == true;
            });
            this.inputList = newArray;

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              jsonData.resIdv.wareInOutHistDetail.forEach((row) => {
                let lotObj = commonUtil.convertLotNo(row.lotNo);
                this.inputList.push({
                  isHeader: false,
                  wareInOutDate: dateTimeHelper.convertUTC2JST(group).substr(0, 10), // 入出庫日 プルダウン用
                  entExtDate: dateTimeHelper.convertUTC2JST(row.inOutDate).substr(0, 10), // 入出庫日
                  wareInOut: row.opeDispName, // 入/出
                  carryOver: commonUtil.formatToCurrency(items[0].carryOver), // 繰越
                  entering: commonUtil.formatToCurrency(Number(row.wareIn)), // 入庫
                  exiting: commonUtil.formatToCurrency(Number(row.wareOut)), // 出庫
                  exitingckeck: Number(row.wareOut), //出庫チェック用
                  remainingQty: commonUtil.formatToCurrency(items[0].remainingQty), // 残数
                  inOutDate: dateTimeHelper.convertUTC2JST(group).substr(0, 10), // 作業日
                  inOutUserCd: row.inOutUserCd,
                  worker: row.inOutUserName, // 作業者
                  managementNo: row.itemManageNo, // 管理No
                  billingSlipNo: row.listNo,
                  location: row.locationNo, // ロケNo
                  locationSid: row.locationSid,
                  lot: lotObj.original.lot, // ロット
                  serial: lotObj.original.serial, //S/N
                  dueDate: lotObj.original.dueDate, // 期限日
                  lotSubkey1: lotObj.original.lotSubkey1, // 付属キー１
                  lotSubkey2: lotObj.original.lotSubkey2, // 付属キー２
                  opeDispName: row.opeDispName,
                  qualityDiv: row.qualityDiv,
                  status: row.qualityDivName, // 品質区分
                  destination: row.relatedPartyName,
                  toSid: row.toSid,
                  wareIn: row.wareIn,
                  wareOut: row.wareOut,
                  warehouse: row.warehouseName,
                  warehouseSid: row.warehouseSid,
                });
                this.inputList.sort(function (a, b) {
                  return a.wareInOutDate > b.wareInOutDate;
                });
              });
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("toggleOpen(group, items) Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    toggleClose() {
      // スクロールバー処理
      // if (this.inputScrollList.length < 10) {
      //   document.getElementById("listDataSpecial").classList.add("scrollTypeClose");
      // }
    },

    // 取引先API接続
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // ***プルダウンセット処理***
    // 取引先
    setSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    // 品番
    setProductCode() {
      for (var i = 0; i < this.productCodeList.length; i++) {
        if (this.productCodeList[i].value == this.productCodeSelected) {
          return this.productCodeList[i].name;
        }
      }
    },
    // 詳細ポップアップ
    openUpdateDelete(item) {
      // // 選択した行のアイテム
      this.dialogDetails = true;
      this.detailDialogData.clientSid = this.suppliersSelected;
      this.detailDialogData.clientName = this.setSupplier();
      this.detailDialogData.itemManageNo = item.managementNo;
    },
    // ダイアログクローズ
    closePopup(isComplete) {
      this.dialogDetails = false;
      if (isComplete) {
        this.infoDialog.message = messsageUtil.getMessage("P-CNT-006_002_3");
        this.infoDialog.title = "結果";
        this.infoDialog.isOpen = true;
        this.getList();
      }
    },
  },
  created() {
    //取引先
    this.clientSid = this.$route.params.clientSid;
    this.suppliersSelected = this.$route.params.clientSid;
    // 基準日
    this.referenceDate = this.$route.params.referenceDate;

    // 基準日カレンダー
    this.arrAchieveDateTo = this.$route.params.referenceDate;

    //カレンダー
    this.arrAchieveDateToCal = this.$route.params.referenceDateCal;

    if (this.referenceDate) {
      this.getList();
    } else {
      this.suppliersSelected = "";
    }

    let sumList = [];

    sumList.push({
      isHeader: true,
      wareInOutDate: "", // 入出庫日 プルダウン用
      entExtDate: "", // 入出庫日
      wareInOut: i18n.tc("label.lbl_totalSum"), // 入/出
      carryOver: 0, // 繰越
      entering: 0, // 入庫
      exiting: 0, // 出庫
      remainingQty: 0, // 残数
      billingSlipNo: "", // 伝票番号
      warehouse: "",
      lot: "", // ロット
      qualityDiv: "",
      status: "", // 品質区分
      location: "", // ロケNo
      destination: "", // 出荷先
      inOutDate: "", // 作業日
      worker: "", // 作業者
      managementNo: "", // 管理No
    });
    this.inputSumList = [];
    this.inputSumList = sumList;
  },
  computed: {
    classHeightSet() {
      return {
        firstListHeight: this.isActive,
      };
    },
  },
  watch: {
    inputList(val) {
      if (!val) {
        this.totalCarryOver = 0;
        this.totalenteringSum = 0;
        this.totalexitingSum = 0;
        this.totalremainingQty = 0;
      }
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    shippingScheduleDateFromCal(val) {
      this.shippingScheduleDateFrom = this.formatDate(val);
    },
    shippingScheduleDateToCal(val) {
      this.shippingScheduleDateTo = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getList();
    },
    suppliersSelected() {
      // 品番/品名クリア
      this.productCodeList = [];
      this.productCodeSelected = "";
    },

    search(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.productCodeList = list.concat(result[0]);
              this.productCodeList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.productCodeList.length = 0;
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-left: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}
.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
.link {
  text-decoration: none;
}
.diff {
  background-color: #ffc0cb;
  // background-color:#d09cfc; 出荷保障の色
}
.diff2 {
  background-color: #d09cfc;
  // background-color:#d09cfc; 出荷保障の色
}
.marginRightNone {
  margin-right: 0px;
}

.accordion {
  margin: 15px;
}

#listDataSpecial {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child {
      background-color: #eee;
      height: 2rem;
    }
    // グループヘッダー
    .v-row-group__header {
      background-color: #dde5f0;
    }
    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #dde5f0;
    }

    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 1.75rem !important;
      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    //テーブルデータ部分
    td {
      font-size: large;
      // 詳細データ 入庫
      &:nth-child(5) {
        text-align: right;
      }
      // 詳細データ 出庫
      &:nth-child(6) {
        text-align: right;
      }
    }
  }
  white-space: nowrap;
  width: 100%;
  // overflow-y: scroll;
  // overflow-y: hidden;
}

#listSumDataSpecial {
  ::-webkit-scrollbar-thumb {
    background: none !important;
  }
  ::-webkit-scrollbar-track {
    background: none !important;
  }
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child {
      background-color: #eee;
      height: 2rem;
    }
    // グループヘッダー
    .v-row-group__header {
      background-color: #dde5f0;
      height: 0px;
    }
    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #ffffff;
    }

    th {
      font-size: large !important;
      color: #ffffff;
      background: #ffffff;
      height: 1.75rem !important;
      box-shadow: none !important;

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    //テーブルデータ部分
    td {
      font-size: large;
      // 詳細データ 合計
      &:nth-child(4) {
        text-align: right;
      }
      // 詳細データ 繰越
      &:nth-child(5) {
        text-align: right;
      }
      // 詳細データ 入庫
      &:nth-child(6) {
        text-align: right;
      }
      // 詳細データ 出庫
      &:nth-child(7) {
        text-align: right;
      }
      // 詳細データ 残数
      &:nth-child(8) {
        text-align: right;
      }
    }
  }
  white-space: nowrap;
  pointer-events: none;
  font-weight: 700;
  // overflow-y: scroll;
  overflow-x: hidden !important;
}

.scrollType::-webkit-scrollbar {
  display: none;
}

.scrollTypeClose {
  overflow-y: scroll;
}

.data-dispointer {
  pointer-events: none;
}

.header-position {
  text-align: right;
}

.firstListHeight {
  &.v-data-table--fixed-header ::v-deep {
    //テーブルデータ部分
    td {
      font-size: large;
      height: 0px;
      // padding-top: 16px;
      // padding-bottom: 16px;
    }
  }
}

.table-scroll-host {
  grid-area: content;
  overflow: scroll;
}

.row-header {
  visibility: hidden !important;
  background-color: red($color: #ff0000);
}

.text-overflow {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-mdi-plus {
  width: 20px;
  overflow: hidden;
  white-space: nowrap;
}

.header-warehouse {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.txt-wareInOut {
  width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}

.txt-warehouse {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-inOutDate {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
}

.text-location {
  width: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tableData {
  border-collapse: collapse;
  table-layout: fixed;
}

::v-deep .negative-inventory {
  color: #dd0000;
}
</style>
