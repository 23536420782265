<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-col>
        <div id="lbl-screen-name">{{ $t("label.lbl_opeHistUpdate") }}</div>
      </v-col>
    </v-container>
    <v-container fluid>
      <v-row class="search-row">
        <span class="item-spacer">&nbsp;</span>
        <!-- 取引先 -->
        <div class="serch-textbox-arrivalNo">
          <v-text-field
            dense
            readonly
            v-model="suppliersSelectedName"
            :label="$t('label.lbl_supplier')"
            :hint="suppliersSelectedName"
            class="txt-single"
            persistent-hint
            margin-bottom="0"
            pa-3
          />
        </div>
        <span class="item-spacer">&nbsp;</span>
        <!-- 管理番号 -->
        <div class="serch-textbox-arrivalNo">
          <v-text-field
            dense
            readonly
            v-model="itemManageNo"
            :label="$t('label.lbl_managementNo')"
            :hint="itemManageNo"
            class="txt-single"
            persistent-hint
            margin-bottom="0"
            pa-3
          />
        </div>
      </v-row>
    </v-container>
    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        :headers="headerItems"
        :items="inputList"
        :fixed-header="true"
        disable-sort
        disable-pagination
        :hide-default-footer="true"
        height="500px"
      >
        <!-- 工程名 -->
        <template v-slot:[`item.procNm`]="{ item }">
          {{ item.procNm }}
        </template>
        <!-- 伝票No-->
        <template v-slot:[`item.listNo`]="{ item }">
          <div class="listNo-style">{{ item.listNo }}</div>
        </template>
        <!-- 実績日-->
        <template v-slot:[`item.yyyymmddHhmmss`]="{ item }">
          {{ item.yyyymmddHhmmss }}
        </template>
        <!-- 品番-->
        <template v-slot:[`item.itemCd`]="{ item }">
          <div class="listNo-style">{{ item.itemCd }}<br />{{ item.incidental }}</div>
        </template>
        <template v-slot:[`item.locationNo`]="{ item }">
          <!-- ロケ -->
          {{ item.locationNo }}
        </template>
        <template v-slot:[`item.signStock`]="{ item }">
          <!-- 工程コードが入庫以下かつ一覧に出庫以降がない場合修正可 -->
          <div v-if="item.procCd < 300 && isUpdateFlg">
            <v-text-field
              class="input-number"
              dense
              outlined
              v-model="item.signStock"
              :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck, rules.limitNumLength]"
              @change="changeQty(item)"
              @click="clickPop(item.signStock)"
              clear-icon="mdi-close-circle"
              clearable
            />
          </div>
          <!-- 出庫以降もしくは一覧に出庫以降があれば修正不可 -->
          <div v-else>
            {{ item.signStock }}
          </div>
        </template>
        <!-- * -->
      </v-data-table>
      <div class="text-popBtn pt-2">
        <v-btn class="other-btn" @click="btnClose" style="float: right">
          <!-- 閉じるボタン -->
          {{ $t("btn.btn_close") }}
        </v-btn>
        <v-btn class="post-btn" @click="btnUpdate" style="float: right">
          <!-- 更新登録ボタン -->
          {{ $t("btn.btn_updateInsert") }}
        </v-btn>
      </div>
      <v-dialog v-model="simpleDialog" persistent="" no-click-animation>
        <SimpleDialog
          :isShow.sync="simpleDialog"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :firstPageFlag="infoDialog.firstPageFlag"
          :homePageFlag="infoDialog.homePageFlag"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { i18n } from "../../lang/lang.js";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";

export default {
  name: appConfig.SCREEN_ID.P_MNG_008,
  components: {
    Loading,
    SimpleDialog,
  },
  props: {
    entity: { type: Object, default: null },
    detailDialogData: {},
    hoge1: {
      default: "",
    },
    isOpenDetailDialog: { type: Boolean, default: false },
  },
  data: () => ({
    simpleDialog: false,
    // 修正可能フラグ
    isUpdateFlg: true,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 取引先
    suppliersSelected: "",
    itemManageNo: "",
    suppliersSelectedName: "",
    headerItems: [
      // 工程名
      {
        text: i18n.tc("label.lbl_opeHistName"),
        width: "15%",
        value: "procNm",
        sortable: false,
        align: "left",
      },
      {
        text: i18n.tc("label.lbl_slipNumber"),
        value: "listNo",
        width: "15%",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_achieveDate"),
        value: "yyyymmddHhmmss",
        width: "15%",
        align: "center",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_productCode"),
        value: "itemCd",
        width: "15%",
        align: "left",
        sortable: true,
      },
      // ロケ
      {
        text: i18n.tc("label.lbl_locationNo"),
        value: "locationNo",
        width: "15%",
        sortable: false,
        align: "left",
      },
      // 数量
      {
        text: i18n.tc("label.lbl_achievecount"),
        value: "signStock",
        width: "15%",
        align: "left",
        sortable: false,
      },
    ],
    // 仮データ
    inputList: [],

    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      homePageFlag: false,
    },
    rules: {
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      limitNumLength: (value) =>
        value == null ||
        String(value).replaceAll(",", "").length < 10 ||
        i18n.tc("check.chk_limitNumLength"), // 文字数の制約
      isRangeCheck: (value) => {
        if (value == "" || value == null) {
          // 値が入っていない場合はチェックなし
          return true;
        } else {
          // 処理なし
        }
        if (-1 < Number(commonUtil.zen2han(value).replaceAll(",", ""))) {
          return true;
        } else {
          return i18n.tc("check.chk_inputMore");
        }
      },
    },
  }),
  methods: {
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    changeQty(item) {
      item.signStock = commonUtil.formatToCurrency(Number(item.signStock));
    },
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.suppliersSelected = this.entity.clientSid;
      this.suppliersSelectedName = this.entity.clientName;
      this.itemManageNo = this.entity.itemManageNo;

      // 詳細画面を開く
      this.getDetail();
    },

    // 在庫明細API
    getDetail() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 検索条件などのパラメータを設定する

      config.params.itemManageNo = this.itemManageNo;

      // console.debug("getDetail() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_OPEHIST_ALLDTL, config)
          .then((response) => {
            // console.debug("getDetail() Response", response);
            const list = [];
            const jsonData = JSON.parse(JSON.stringify(response.data));
            let opeHists = jsonData.resIdv.opeHists;
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.isUpdateFlg = true;
              for (let i = 0; i < opeHists.length; i++) {
                if (opeHists[i].procCd > 300) {
                  this.isUpdateFlg = false;
                }
                let addProcName = "";
                if (
                  opeHists[i].opeInstructionsSid != null &&
                  opeHists[i].opeInstructionsSid != ""
                ) {
                  addProcName = "（" + i18n.tc("menu.txt_moving") + "）";
                } else if (opeHists[i].opeDispName.indexOf("返品") > 0) {
                  addProcName = "（" + i18n.tc("menu.txt_return") + "）";
                }
                list.push({
                  locationNo: opeHists[i].locationNo,
                  procCd: opeHists[i].procCd,
                  procNm: opeHists[i].procName + addProcName,
                  signStock: commonUtil.formatToCurrency(Number(opeHists[i].signStock)),
                  opeHistSid: opeHists[i].opeHistSid,
                  updateDatetime: opeHists[i].updateDatetime,
                  apiSignStock: opeHists[i].signStock,
                  yyyymmddHhmmss: dateTimeHelper
                    .convertUTC2JST(opeHists[i].yyyymmddHhmmss)
                    .split(" ")[0],
                  listNo:
                    opeHists[i].inListNo != null ? opeHists[i].inListNo : opeHists[i].outListNo,
                  itemCdShow: opeHists[i].itemCd,
                  itemCd: opeHists[i].itemCd.substring(0, 50).trim(),
                  incidental: opeHists[i].itemCd.slice(-50).trim(),
                });
              }
              this.inputList = list;
              resolve(response);
              // エラー時
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.simpleDialog = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("getDetail() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    //ダイアログを閉じる
    btnClose() {
      this.$emit("closePopup", false);
      this.$emit("update:isOpenDetailDialog", false);
      this.inputList = [];
    },
    btnUpdate() {
      let updateList = [];
      for (let i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].signStock.replaceAll(",", "") != this.inputList[i].apiSignStock) {
          updateList.push({
            opeHistSid: this.inputList[i].opeHistSid,
            updateDatetime: this.inputList[i].updateDatetime,
            signStock: this.inputList[i].signStock.replaceAll(",", ""),
            procCd: this.inputList[i].procCd,
            opeDispName: i18n.tc("label.lbl_opeHistUpdate"),
          });
        }
      }
      // 更新されたものがない場合、エラー
      if (updateList.length < 1) {
        this.infoDialog.message = messsageUtil.getMessage("P-BIL-005_001_E");
        this.infoDialog.title = "結果";
        this.infoDialog.firstPageFlag = true;
        this.simpleDialog = true;
        return;
      }
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MNG_008;
      body.reqIdv.opeHists = updateList;
      this.loadingCounter = 1;
      // console.debug("btnUpdate() body", body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_OPEHIST_ALLDTL, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("btnUpdate() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              //this.$emit("click", true);
              this.$emit("closePopup", true);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
  },
  watch: {
    entity: {
      handler(newValue, oldValue) {
        console.info("handler", newValue, oldValue);
      },
    },
    // eslint-disable-next-line no-unused-vars
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  padding: none;
  height: 3rem;
}

// .pad {
//   padding-left: 0;
//   padding-right: 0;
// }

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
