<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_CompareScheduleAchievements" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrAchieveDateTo"
                class="txt-single date-style"
                :label="'基準日'"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="arrAchieveDateToCal" @input="dateMenu = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="preDateOfAchieveTo"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDateOfAchieveTo"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn class="get-btn" @click="getList()">{{ $t("btn.btn_search") }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>
              <!-- {{ this.arrivalList[1].freeCostDiv }} -->
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="530px"
        >
          <template v-slot:[`item.productNm`]="{ item }">
            <v-btn color="blue darken-1" text @click="openPopup(items)">
              {{ item.productNm }}
            </v-btn>
          </template>
          <template v-slot:[`item.accInfos`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.accInfos }}
                </div>
              </template>
              <span>{{ item.accInfos }}</span>
            </v-tooltip>
          </template>
          <!-- 取引先名 -->
          <template v-slot:[`item.clientName`]="{ item }">
            <!-- <div>{{ item.clientSid }}</div> -->
            <div>{{ item.clientName }}</div>
          </template>
          <!-- 予定：実績文言表示 -->
          <template v-slot:[`item.diffName`]>
            <div>予定</div>
            <div>実績</div>
          </template>
          <!-- 入荷予定実績 -->
          <template v-slot:[`item.planRcv`]="{ item }">
            <!-- 差が0の場合 -->
            <div
              v-if="item.planRcv.replace(/,/g, '') - item.actualRcv.replace(/,/g, '') == 0"
              class="marginRightNone"
            >
              <a @click="pageTransition(item)">
                <div>{{ item.planRcv.toLocaleString() }}</div>
                <div>{{ item.actualRcv }}</div>
              </a>
            </div>

            <div v-else class="diff2">
              <a @click="pageTransition(item)">
                <div>{{ item.planRcv.toLocaleString() }}</div>
                <div>{{ item.actualRcv }}</div>
              </a>
            </div>
          </template>
          <!-- 入庫予定実績 -->
          <template v-slot:[`item.planEnt`]="{ item }">
            <div v-if="item.planEnt.replace(/,/g, '') - item.actualEnt.replace(/,/g, '') == 0">
              <a @click="pageTransitionEnt(item)">
                <div>{{ item.planEnt }}</div>
                <div>{{ item.actualEnt }}</div>
              </a>
            </div>
            <div v-else class="diff2">
              <a @click="pageTransitionEnt(item)">
                <div>{{ item.planEnt }}</div>
                <div>{{ item.actualEnt }}</div>
              </a>
            </div>
          </template>
          <!-- 出庫予定実績 -->
          <template v-slot:[`item.planExt`]="{ item }">
            <div v-if="item.planExt.replace(/,/g, '') - item.actualExt.replace(/,/g, '') == 0">
              <a @click="pageTransitionExt(item)">
                <div>{{ item.planExt }}</div>
                <div>{{ item.actualExt }}</div>
              </a>
            </div>
            <div v-else class="diff2">
              <a @click="pageTransitionExt(item)">
                <div>{{ item.planExt }}</div>
                <div>{{ item.actualExt }}</div>
              </a>
            </div>
          </template>
          <!-- 出荷予定実績 -->
          <template v-slot:[`item.planShp`]="{ item }">
            <div v-if="item.planShp.replace(/,/g, '') - item.actualShp.replace(/,/g, '') == 0">
              <a @click="pageTransitionShip(item)">
                <div>{{ item.planShp }}</div>
                <div>{{ item.actualShp }}</div>
              </a>
            </div>
            <div v-else class="diff2">
              <a @click="pageTransitionShip(item)">
                <div>{{ item.planShp }}</div>
                <div>{{ item.actualShp }}</div>
              </a>
            </div>
          </template>
        </v-data-table>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <!-- 進捗情報詳細モーダル-->
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
export default {
  name: appConfig.SCREEN_ID.P_MNG_001,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    apiData: [],
    arrivalList: [],
    u_10: false,
    singleSelect: false,
    selected: [],
    pListPopup: false,
    // 総件数
    totalCount: 0,
    // 入荷予定日付
    arrivalScheduleDateCal: "",
    arrivalScheduleDate: "",
    expireDateCal: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    checkFlg: false,
    inListSid: "", // 修正削除画面に渡すid
    // 取引先名+取引先コード
    suppliersNm: "",
    // 入荷先
    arrivalSelected: "",
    // 取引先
    suppliersSelected: "",
    // メニュー
    openMenu: null,
    // 入荷予定No.
    txt_arrivalScheduleNo: "",
    // 入荷実績日付from
    arrAchieveDateFrom: "",
    arrAchieveDateFromCal: "",
    // 入荷実績日付to
    arrAchieveDateTo: "",
    arrAchieveDateToCal: "",
    // 取引先SID
    clientSid: "",
    // 取引先名
    clientName: "",
    //入荷予定数
    planRcv: "",
    //入荷実績数
    actualRcv: "",
    //入庫指示数
    planEnt: "",
    //入庫実績数
    actualEnt: "",
    //出庫指示数
    planExt: "",
    //出庫実績数
    actualExt: "",
    //出荷予定数
    planShp: "",
    //出荷実績数
    actualShp: "",
    //予定：実績を表示するための値
    diffName: "",
    arrivalScheduleDateJ: "",
    // 入荷予定日付キャレンダー
    dateMenu: false,
    dateMenu2: false,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    // ソート
    sortItem: "",
    sortOptions: {},
    alertMessage: "",
    // ヘッダ
    headerItems: [
      {
        text: "取引先",
        value: "clientName",
        width: "7%",
        align: "left",
        class: "diff2",
        sortable: true,
      },
      { text: "", value: "diffName", width: "3%", align: "right", sortable: false }, //予定、実績の文言を表示するため
      { text: "入荷", value: "planRcv", width: "7%", align: "right", sortable: true },
      { text: "入庫", value: "planEnt", width: "7%", align: "right", sortable: true },
      { text: "出庫", value: "planExt", width: "7%", align: "right", sortable: true },
      { text: "出荷", value: "planShp", width: "7%", align: "right", sortable: true },
    ],
    detailDialogData: {},
    inputList: [],
    supplierList: [{ text: "データがありません。", value: "" }],

    rules: {
      supplierRequired: (value) => !!value || "取引先を入力してください",
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
    },
  }),
  methods: {
    openPopup() {
      this.pListPopup = true;
    },

    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.getList();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getList();
    },

    // 初期化
    init() {
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      if (this.$route.params.referenceDate) {
        // console.debug("履歴画面から遷移");
      } else {
        this.suppliersSelected = "";
        this.arrivalScheduleDate = "";
        this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      }
      this.getSupplier();
    },
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    // 入荷実績日付をー１日
    preDateOfAchieveTo() {
      if (this.arrAchieveDateToCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrAchieveDateToCal != toDate) {
        this.arrAchieveDateToCal = toDate;
      } else {
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    // 入荷実績日付を+１日
    nextDateOfAchieveTo() {
      if (this.arrAchieveDateToCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrAchieveDateToCal != toDate) {
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.arrAchieveDateToCal = null;
      }
    },

    /**
     * 入荷予定登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    //入荷作詳細画面ダイアログ閉じるボタン
    onSubmit() {
      this.pListPopup = false;
    },
    //検索ボタン押下時
    getList() {
      if (!this.arrAchieveDateTo) {
        return;
      }
      let inputCheckResult = false;

      /**
       * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
       * エラーがでるので回避するためこのようなコードとなっている。
       */
      if (this.checkFlg) {
        // 登録画面からの遷移の場合、入力チェックは実施しない。
        inputCheckResult = true;
      } else {
        // 上記外の場合はチェックを行う。
        inputCheckResult = this.$refs.form.validate();
      }
      // 画面遷移フラグをfalseに戻す
      this.checkFlg = false;

      // 入力チェック実施
      if (inputCheckResult) {
        this.alertMessage = "";
        // ローディング画面表示ON
        this.loadingCounter = 1;

        this.$set(this.inputList, "", "");
        // httpClientで使用する共通IFパラメータ等を作成する。
        const config = this.$httpClient.createGetApiRequestConfig();

        if (this.arrivalScheduleDateJ) {
          config.params.reqComReferenceDate = this.arrivalScheduleDateJ;
          this.arrivalScheduleDateJ = null;
        } else {
          config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.arrAchieveDateTo);
        }
        if (this.suppliersSelected) {
          config.params.clientSid = this.suppliersSelected;
        }
        // ソート
        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
        }
        // console.debug("getList() Config", config);
        // 接続先のAPI_URLを入力
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_PLANACTUALDIFF, config)
            .then((response) => {
              this.inputList.splice(0);
              this.totalCount = 0;

              const jsonData = JSON.parse(JSON.stringify(response.data));
              // console.debug(`getList() Response`, response);
              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                this.arrivalList = jsonData.resIdv.planActualDiff;
                // for(){}繰り返し処理を行い、配列を格納する処理を記述する
                for (let i = 0; i < this.arrivalList.length; i++) {
                  let data = {
                    clientSid: this.arrivalList[i].clientSid, //取引先SID
                    clientName: this.arrivalList[i].clientName, //取引先名
                    //入荷予定数
                    planRcv: commonUtil.formatToCurrency(Number(this.arrivalList[i].planRcv)),
                    actualRcv: commonUtil.formatToCurrency(Number(this.arrivalList[i].actualRcv)), //入荷実績数
                    planEnt: commonUtil.formatToCurrency(Number(this.arrivalList[i].planEnt)), //入庫指示数
                    actualEnt: commonUtil.formatToCurrency(Number(this.arrivalList[i].actualEnt)), //入庫実績数
                    planExt: commonUtil.formatToCurrency(Number(this.arrivalList[i].planExt)), //出庫指示数
                    actualExt: commonUtil.formatToCurrency(Number(this.arrivalList[i].actualExt)), //出庫実績数
                    planShp: commonUtil.formatToCurrency(Number(this.arrivalList[i].planShp)), //出荷予定数
                    actualShp: commonUtil.formatToCurrency(Number(this.arrivalList[i].actualShp)), //出荷実績数
                  };
                  this.inputList.push(data);
                  this.totalCount = this.inputList.length;
                }
                // エラー時
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
              }
              resolve(response);
            })
            .catch((resolve) => {
              console.error("getList() Resolve", resolve);
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      } else {
        // エラーがある場合、処理なし
      }
    },
    // 取引先API接続
    getSupplier() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getSupplier() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                });
              });
              this.supplierList = list;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getSupplier() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    pageTransition(item) {
      if (this.$refs.form.validate()) {
        // // 選択した行のアイテム
        this.editedIndex = this.inputList.indexOf(item);
        // 履歴画面へ渡す値を設定します。
        this.$router.push({
          name: appConfig.SCREEN_ID.P_MNG_002, //"ReceivingHistory",
          params: {
            clientSid: this.inputList[this.editedIndex].clientSid,
            clientName: this.inputList[this.editedIndex].clientName,
            ReferenceDate: dateTimeHelper.convertUTC(this.arrAchieveDateTo),
            // 予定実績対比画面の検索条件の値
            serchClientSid: this.suppliersSelected,
            // 基準日カレンダー
            serchReferenceDateCal: this.arrAchieveDateToCal,
            checkFlg: this.checkFlg,
          },
        });
      }
    },
    pageTransitionEnt(item) {
      if (this.$refs.form.validate()) {
        // // 選択した行のアイテム
        this.editedIndex = this.inputList.indexOf(item);
        // 履歴画面へ渡す値を設定します。
        this.$router.push({
          name: appConfig.SCREEN_ID.P_MNG_003, //"EnteringHistory",
          params: {
            clientSid: this.inputList[this.editedIndex].clientSid,
            clientName: this.inputList[this.editedIndex].clientName,
            ReferenceDate: dateTimeHelper.convertUTC(this.arrAchieveDateTo),
            // 予定実績対比画面の検索条件の値
            serchClientSid: this.suppliersSelected,
            // 基準日カレンダー
            serchReferenceDateCal: this.arrAchieveDateToCal,
            checkFlg: this.checkFlg,
          },
        });
      }
    },
    pageTransitionExt(item) {
      if (this.$refs.form.validate()) {
        // // 選択した行のアイテム
        this.editedIndex = this.inputList.indexOf(item);
        // 履歴画面へ渡す値を設定します。
        this.$router.push({
          name: appConfig.SCREEN_ID.P_MNG_004,
          params: {
            clientSid: this.inputList[this.editedIndex].clientSid,
            clientName: this.inputList[this.editedIndex].clientName,
            ReferenceDate: dateTimeHelper.convertUTC(this.arrAchieveDateTo),
            // 予定実績対比画面の検索条件の値
            serchClientSid: this.suppliersSelected,
            // 基準日カレンダー
            serchReferenceDateCal: this.arrAchieveDateToCal,
            checkFlg: this.checkFlg,
          },
        });
      }
    },
    pageTransitionShip(item) {
      if (this.$refs.form.validate()) {
        // // 選択した行のアイテム
        this.editedIndex = this.inputList.indexOf(item);
        // 履歴画面へ渡す値を設定します。
        this.$router.push({
          name: appConfig.SCREEN_ID.P_MNG_005, //"ShipHistory",
          params: {
            clientSid: this.inputList[this.editedIndex].clientSid,
            clientName: this.inputList[this.editedIndex].clientName,
            ReferenceDate: dateTimeHelper.convertUTC(this.arrAchieveDateTo),
            // 予定実績対比画面の検索条件の値
            serchClientSid: this.suppliersSelected,
            // 基準日カレンダー
            serchReferenceDateCal: this.arrAchieveDateToCal,
            checkFlg: this.checkFlg,
          },
        });
      }
    },
  },
  created() {
    this.arrivalScheduleDateJ = this.$route.params.arrivalScheduleDateJ;
    //取引先
    this.clientSid = this.$route.params.clientSid;
    this.suppliersSelected = this.$route.params.searchclientSid;
    // 基準日
    this.referenceDate = this.$route.params.referenceDate;

    // 基準日カレンダー
    this.arrAchieveDateTo = this.$route.params.referenceDate;
    //カレンダー
    this.arrAchieveDateToCal = this.$route.params.referenceDateCal;
    this.checkFlg = this.$route.params.checkFlg;
    if (this.referenceDate) {
      this.getList();
    } else {
      this.suppliersSelected = "";
    }
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    arrivalScheduleDateCal(val) {
      this.arrivalScheduleDate = this.formatDate(val);
    },
    arrAchieveDateFromCal(val) {
      this.arrAchieveDateFrom = this.formatDate(val);
    },
    arrAchieveDateToCal(val) {
      this.arrAchieveDateTo = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getList();
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MNG_001_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getList();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}
.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
.link {
  text-decoration: none;
}
.diff {
  background-color: #ffc0cb;
  // background-color:#d09cfc; 出荷保障の色
}
.diff2 {
  background-color: #d09cfc;
  // background-color:#d09cfc; 出荷保障の色
}
.marginRightNone {
  margin-right: 0px;
}
// #listData {
//   &.v-data-table--fixed-header ::v-deep {
//     //テーブルデータ部分
//     td {
//       &:nth-child(7) {
//         color: red;
//         font-weight: 900;
//       }
//     }
//   }
// }
</style>
