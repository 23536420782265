<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_opeHistList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="clientSelected"
                :items="clientList"
                :label="$t('label.lbl_supplier')"
                :hint="setClientHint()"
                :rules="[rules.clientRequired]"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="fromDate"
                class="txt-single date-style"
                :label="$t('label.lbl_achieveDate') + '（From）'"
                @change="changeFromDate"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="dateFromErr"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="menuFromDate"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="fromDateCal" @input="menuFromDate = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="preDateOfFromDate"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDateOfFromDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダーの間 -->
            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- toカレンダー -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="toDate"
                class="txt-single date-style"
                :label="$t('label.lbl_achieveDate') + '（To）'"
                @change="changeToDate"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="dateToErr"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="menuToDate"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="toDateCal" @input="menuToDate = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="preDateOfToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDateOfToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 商品コード -->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="itemSelected"
                :items="itemList"
                :hint="setItemHint()"
                @change="changeProductNm"
                :search-input.sync="itemSearch"
                :rules="[rules.productRequired]"
                :label="$t('label.lbl_productCnCd')"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
            <span class="item-spacer">&nbsp;</span>

            <!-- ロケ-->
            <div class="search-autocomplete first-search-item" style="float: left">
              <v-autocomplete
                dense
                v-model="locationSelected"
                :items="locationList"
                :label="$t('label.lbl_location')"
                :search-input.sync="locationSearch"
                class="txt-single"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
          </v-row>
          <v-row>
            <span class="item-spacer">&nbsp;</span>

            <!--管理番号-->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="itemManageNo"
                maxlength="16"
                :label="$t('label.lbl_managementNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <span class="item-spacer">&nbsp;</span>

            <!--伝票No-->
            <div class="textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="slipNo"
                maxlength="50"
                :label="$t('label.lbl_slipNumber')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 品質区分-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="qualitySelected"
                :items="qualityList"
                :label="$t('label.lbl_qualityDiv')"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 責任区分-->
            <v-btn-toggle
              v-model="blameSelected"
              mandatory
              color="blue darken-2"
              class="toggle black--text"
            >
              <v-btn
                mandatory
                class="res-btn"
                v-for="item in blameList"
                :key="item.value"
                :value="item.value"
                small
                v-bind:disabled="isDisabledBlame"
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
            <!-- 有償/無償-->
            <span class="item-spacer">&nbsp;</span>
            <div style="float: left">
              <v-btn-toggle
                mandatory
                v-model="freeCostSelected"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in freeCostList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle>
            </div>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn class="get-btn" @click="btnSearch()">{{ $t("btn.btn_search") }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="530px"
        >
          <!-- 工程名 -->
          <template v-slot:[`item.procNm`]="{ item }">
            {{ item.procNm }}
          </template>
          <!-- 伝票No-->
          <template v-slot:[`item.listNo`]="{ item }">
            <div class="listNo-style">{{ item.listNo }}</div>
          </template>
          <!-- 実績日-->
          <template v-slot:[`item.yyyymmddHhmmss`]="{ item }">
            {{ item.yyyymmddHhmmss }}
          </template>
          <!-- 品番-->
          <template v-slot:[`item.itemCd`]="{ item }">
            <div class="listNo-style">{{ item.itemCd }}<br />{{ item.incidental }}</div>
          </template>
          <!-- 有償無償-->
          <template v-slot:[`item.freeCostDivName`]="{ item }">
            {{ item.freeCostDivName }}
          </template>
          <!-- 品質区分-->
          <template v-slot:[`item.qualityDivName`]="{ item }">
            {{ item.qualityDivName }}
          </template>
          <!-- 責任区分-->
          <template v-slot:[`item.blameDivName`]="{ item }">
            {{ item.blameDivName }}
          </template>
          <!-- 管理番号 -->
          <template v-slot:[`item.itemManageNo`]="{ item }">
            <v-btn color="blue darken-1" text @click="openUpdateDelete(item)">
              <span class="strageQty">{{ item.itemManageNo }}</span>
            </v-btn>
          </template>
          <!-- ロケーションNo -->
          <template v-slot:[`item.locationNo`]="{ item }">
            {{ item.locationNo }}
          </template>
          <!-- 数量 -->
          <template v-slot:[`item.signStock`]="{ item }">
            {{ item.signStock }}
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
        <!-- 詳細画面ポップアップ -->
        <v-dialog v-model="dialogDetails" :max-width="2000">
          <ManageOpeHistUpdate
            :isOpenDetailDialog.sync="dialogDetails"
            :entity="detailDialogData"
            class="overflow"
            v-on:closePopup="closePopup"
            @click="closePopup"
          />
        </v-dialog>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ManageOpeHistUpdate from "./ManageOpeHistUpdate.vue";
export default {
  name: appConfig.SCREEN_ID.P_MNG_008,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ManageOpeHistUpdate,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    dateFromErr: "",
    dateToErr: "",
    // 品質区分一覧
    qualityList: [],
    qualitySelected: "",
    // 責任区分
    blameList: [],
    isDisabledBlame: true,
    blameSelected: "",
    oldBlameSelected: "",
    freeCostSelected: "",
    freeCostList: [],
    // 詳細ダイアログ
    dialogDetails: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 選択した商品
    itemSelected: "",
    // 商品一覧
    itemList: [],
    u_10: false,
    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 取引先
    clientSelected: "",
    // メニュー
    openMenu: null,

    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: true,
    },
    // ソート
    sortItem: "",
    sortOptions: {},
    alertMessage: "",
    // ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_opeHistName"),
        value: "procNm",
        width: "10%",
        align: "left",
        class: "diff2",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_slipNumber"),
        value: "listNo",
        width: "13%",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_achieveDate"),
        value: "yyyymmddHhmmss",
        width: "10%",
        align: "center",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_productCode"),
        value: "itemCd",
        width: "16%",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_isPaid"),
        value: "freeCostDivName",
        width: "7%",
        align: "center",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_qualityDiv"),
        value: "qualityDivName",
        width: "7%",
        align: "center",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_blameDiv"),
        value: "blameDivName",
        width: "7%",
        align: "center",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_managementNo"),
        value: "itemManageNo",
        width: "13%",
        align: "center",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_locationNo"),
        value: "locationNo",
        width: "10%",
        align: "center",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_achievecount"),
        value: "signStock",
        width: "7%",
        align: "right",
        sortable: false,
      },
    ],
    detailDialogData: {
      clientSid: "",
      clientName: "",
      itemManageNo: "",
    },

    clientList: [{ text: "データがありません。", value: "" }],
    fromDate: "",
    fromDateCal: "",
    // 出荷日to
    toDate: "",
    toDateCal: "",
    // 伝票No
    slipNo: "",
    // 管理番号
    itemManageNo: "",
    // ロケーション
    locationSelected: "",
    locationList: [],
    menuToDate: false,
    menuFromDate: false,
    itemSearch: "",
    locationSearch: "",
    inputList: [],
    productHint: "",
    rules: {
      supplierRequired: (value) => !!value || "取引先を入力してください",
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      productRequired: (value) => !!value || i18n.tc("check.chk_inputproductCnCd"),
      clientRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
    },
  }),
  methods: {
    openPopup() {
      this.pListPopup = true;
    },

    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.getList();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getList();
    },

    // 初期化
    init() {
      let d = new Date();
      d.setMonth(d.getMonth() - 1);
      this.fromDate = dateTimeHelper.toStringDate(
        "YYYY/MM/DD",
        dateTimeHelper.convertJstDateTime(d)
      );
      this.fromDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST(d));
      this.toDate = dateTimeHelper.toStringNowYYYY_MM_DD1();
      this.toDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.getInitData();
    },
    // 初期データ取得
    getInitData() {
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(null)
      );
      // 品質区分
      const qualityDivList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 有償無償区分
      const freeCostDivList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // 責任区分
      const blameDivList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);
      Promise.all([clientList, qualityDivList, freeCostDivList, blameDivList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.clientList = result[0];
          this.qualityList = result[1];
          this.freeCostList = result[2];
          result[3].splice(0, 1);
          this.blameList = result[3];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 入荷予定登録画面：取引先コードリストボックス取得処理
     */
    setClientHint() {
      for (var i = 0; i < this.clientList.length; i++) {
        if (this.clientList[i].value == this.clientSelected) {
          return this.clientList[i].name;
        }
      }
    },
    // 商品コードセット処理
    setItemHint() {
      for (var i = 0; i < this.itemList.length; i++) {
        if (this.itemList[i].value == this.itemSelected) {
          return this.itemList[i].name;
        }
      }
    },
    changeProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.itemList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.itemSearch = val.text;
      }
      this.itemList = this.itemList.filter((v) => v.value == value);
    },
    // From前日
    preDateOfFromDate() {
      if (this.fromDateCal == null) {
        return;
      }
      let date = new Date(this.fromDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.fromDateCal != toDate) {
        this.fromDateCal = toDate;
      } else {
        this.fromDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    // From翌日
    nextDateOfFromDate() {
      if (this.fromDateCal == null) {
        return;
      }
      let date = new Date(this.fromDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.fromDateCal != toDate) {
        this.fromDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.fromDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    // To前日
    preDateOfToDate() {
      if (this.toDateCal == null) {
        return;
      }
      let date = new Date(this.toDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.toDateCal != toDate) {
        this.toDateCal = toDate;
      } else {
        this.toDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    // To翌日
    nextDateOfToDate() {
      if (this.toDateCal == null) {
        return;
      }
      let date = new Date(this.toDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.toDateCal != toDate) {
        this.toDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.toDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
    /**
     * 日付入力(From)
     */
    changeFromDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.fromDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.fromDateCal = null;
      }
    },

    /**
     * 日付入力(To)
     */
    changeToDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.toDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.toDateCal = null;
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 検索ボータン
    btnSearch() {
      this.page = 1;
      this.getList();
    },
    getList() {
      // バリデーションチェック
      this.$refs.form.validate();
      // 日付に関するエラーメッセージ初期化
      this.dateFromErr = "";
      this.dateToErr = "";
      // 相関チェック
      if (new Date(this.fromDate).getTime() > new Date(this.toDate).getTime()) {
        this.dateToErr = i18n.tc("check.chk_inputFromVali");
        this.dateFromErr = i18n.tc("check.chk_inputToVali");
        return;
      }
      // 取引先が空欄の場合
      if (this.clientSelected == null || this.clientSelected == "") {
        return;
      }
      // 商品が空欄の場合
      if (this.itemSelected == null || this.itemSelected == "") {
        return;
      }

      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.clientSid = this.clientSelected;
      config.params.datetimeFrom = this.fromDate + " 00:00:00.000";
      config.params.datetimeTo = this.toDate + " 23:59:59.999";
      config.params.itemStandardSid = this.itemSelected;
      if (this.locationSelected != null && this.locationSelected != "") {
        config.params.locationSid = this.locationSelected;
      }
      if (this.itemManageNo != null && this.itemManageNo != "") {
        config.params.itemManageNo = this.itemManageNo;
      }
      if (this.slipNo != null && this.slipNo != "") {
        config.params.listNo = this.slipNo;
      }
      if (this.qualitySelected != null && this.qualitySelected != "") {
        config.params.qualityDiv = this.qualitySelected;
      }
      if (this.blameSelected != null && this.blameSelected != "") {
        if (this.qualitySelected == "02") {
          config.params.blameDiv = this.blameSelected;
        }
      }
      if (this.freeCostSelected != null && this.freeCostSelected != "") {
        config.params.freeCostDiv = this.freeCostSelected;
      }
      // ページャー処理
      if (this.page > 2 && this.inputList.length == 0) {
        config.params.reqComPageIndex = "1";
        this.page = 1;
      } else {
        config.params.reqComPageIndex = this.page;
      }
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;
      config.params.reqComSortItem = this.sortItem;
      // console.debug("getList() Request", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_OPEHIST_ALL, config)
          .then((response) => {
            // console.debug("getList() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            this.inputList = [];
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // alert("API接続に成功しました。");
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              let opeHists = jsonData.resIdv.opeHists;
              // for(){}繰り返し処理を行い、配列を格納する処理を記述する
              for (let i = 0; i < opeHists.length; i++) {
                let addProcName = "";
                if (
                  opeHists[i].opeInstructionsSid != null &&
                  opeHists[i].opeInstructionsSid != ""
                ) {
                  addProcName = "（" + i18n.tc("menu.txt_moving") + "）";
                } else if (opeHists[i].opeDispName.indexOf("返品") > 0) {
                  addProcName = "（" + i18n.tc("menu.txt_return") + "）";
                }
                let data = {
                  procCd: opeHists[i].procCd,
                  procNm: opeHists[i].procName + addProcName,
                  itemManageNo: opeHists[i].itemManageNo,
                  locationNo: opeHists[i].locationNo,
                  stock: opeHists[i].stock,
                  signStock: commonUtil.formatToCurrency(Number(opeHists[i].signStock)),
                  freeCostDivName: opeHists[i].freeCostDivName,
                  qualityDivName: opeHists[i].qualityDivName,
                  blameDivName: opeHists[i].blameDivName,
                  yyyymmddHhmmss: dateTimeHelper
                    .convertUTC2JST(opeHists[i].yyyymmddHhmmss)
                    .split(" ")[0],
                  listNo:
                    opeHists[i].inListNo != null ? opeHists[i].inListNo : opeHists[i].outListNo,
                  itemCdShow: opeHists[i].itemCd,
                  itemCd: opeHists[i].itemCd.substring(0, 50).trim(),
                  incidental: opeHists[i].itemCd.slice(-50).trim(),
                };
                this.inputList.push(data);
              }
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
            }
            resolve(response);
            // 検索値保持
            this.searchedSuppliers = this.clientSelected; // 取引先SID
          })
          .catch((resolve) => {
            console.error("getList() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 詳細ポップアップ
    openUpdateDelete(item) {
      // 取引先名を取得
      for (var i = 0; i < this.clientList.length; i++) {
        if (this.clientList[i].value == this.searchedSuppliers) {
          this.clientNm = this.clientList[i].name;
        }
      }
      // // 選択した行のアイテム
      this.dialogDetails = true;
      this.detailDialogData.clientSid = this.searchedSuppliers;
      this.detailDialogData.clientName = this.clientNm;
      this.detailDialogData.itemManageNo = item.itemManageNo;
    },
    // ダイアログクローズ
    closePopup(isComplete) {
      this.dialogDetails = false;
      if (isComplete) {
        this.infoDialog.message = messsageUtil.getMessage("P-CNT-006_002_3");
        this.infoDialog.title = "結果";
        this.infoDialog.isOpen = true;
        this.getList();
      }
    },
  },
  created() {
    this.init();
  },
  computed: {},
  watch: {
    //取引先変更時
    clientSelected() {
      //品番/品名クリア
      this.itemSelected = "";
      this.itemList = [];
    },
    qualitySelected: function (newVal) {
      if (newVal == "02") {
        this.isDisabledBlame = false;
      } else {
        this.isDisabledBlame = true;
      }
    },
    fromDateCal(val) {
      this.fromDate = this.formatDate(val);
    },
    toDateCal(val) {
      this.toDate = this.formatDate(val);
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },

    page: function (newValue) {
      this.page = newValue;
      this.getList();
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MNG_008_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;

          this.getList();
        }
      },
      deep: true,
    },
    itemSearch(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.clientSelected == null || this.clientSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.clientSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.itemList = list.concat(result[0]);
              this.itemList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.itemList.length = 0;
        }
      }
    },
    locationSearch(val) {
      if (val ?? false) {
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const locationBiz = getParameter.getLocationBizUse({ locationNo: val });

          Promise.all([locationBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.locationList = list.concat(result[0]);
              this.locationList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.locationList.length = 0;
        }
      }
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}
.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
.link {
  text-decoration: none;
}
.diff {
  background-color: #ffc0cb;
  // background-color:#d09cfc; 出荷保障の色
}
.diff2 {
  background-color: #d09cfc;
  // background-color:#d09cfc; 出荷保障の色
}
.marginRightNone {
  margin-right: 0px;
}
</style>
